import React, { useState } from "react";
import "./TabBar.css";

const TabBar = () => {
  const [activeMenu, setActiveMenu] = useState(null);

  const handleMenuClick = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  return (
    <div className="tab-bar-container content">
      {activeMenu === "watch" && (
        <ul className="sub-menu">
          <li>
            <a href="/watch">Normal Videos</a>
          </li>
          <li>
            <a href="/watch-vr">VR Videos</a>
          </li>
          <li>
            <a href="/post-video">Post Video</a>
          </li>
        </ul>
      )}
      {activeMenu === "fans" && (
        <ul className="sub-menu">
          <li>
            <a href="/fan-real">Real Creators</a>
          </li>
          <li>
            <a href="/fan-ai">AI Creators</a>
          </li>
          <li>
            <a href="/post-fan-video">Post Video</a>
          </li>
          <li>
            <a href="/become-creator">Become A Creator</a>
          </li>
        </ul>
      )}
      {activeMenu === "services" && (
        <ul className="sub-menu">
          <li>
            <a href="/porn-face">Your Porn Face</a>
          </li>
          <li>
            <a href="/face-swap">Face Swap</a>
          </li>
        </ul>
      )}
      {activeMenu === "nfts" && (
        <ul className="sub-menu">
          <li>
            <a href="/profile-nft">Profile NFT</a>
          </li>
          <li>
            <a href="/onchain-nft">Nude Collectors NFT</a>
          </li>
        </ul>
      )}
      {activeMenu === "market" && (
        <ul className="sub-menu">
          <li>
            <a href="/shop">Marketplace</a>
          </li>
        </ul>
      )}

      <div className="tab-bar">
        <ul className="tab-bar-menu">
          <li className="tab-bar-item">
            <i className="fa fa-home" aria-hidden="true"></i>
            <p></p>
            <a href="/">Home</a>
          </li>
          <li className="tab-bar-item" onClick={() => handleMenuClick("watch")}>
            <span>
              <i className="fa fa-play-circle-o" aria-hidden="true"></i>
              <p></p> Watch
            </span>
          </li>
          <li className="tab-bar-item" onClick={() => handleMenuClick("fans")}>
            <span>
              <i className="fa fa-users" aria-hidden="true"></i>
              <p></p> Fans
            </span>
          </li>
          <li
            className="tab-bar-item"
            onClick={() => handleMenuClick("services")}
          >
            <span>
              <i className="fa fa-th" aria-hidden="true"></i>
              <p></p> Services
            </span>
          </li>
          <li className="tab-bar-item" onClick={() => handleMenuClick("nfts")}>
            <span>
              <i className="fa fa-picture-o" aria-hidden="true"></i>
              <p></p> NFTs
            </span>
          </li>
          <li
            className="tab-bar-item"
            onClick={() => handleMenuClick("market")}
          >
            <span>
              <i className="fa fa-shopping-cart" aria-hidden="true"></i>
              <p></p> Market
            </span>
          </li>
          <li className="tab-bar-item">
            <a href="#">
              <i className="fa fa-envelope-o" aria-hidden="true"></i>
              <p></p> Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TabBar;
