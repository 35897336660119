import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import { formatNumber, showAlert } from "../../utils/helpers";
import axios from "axios";
import Pagination from "../../components/Pagination";

import "./services.css";

function Shop() {
 const [products, setProducts] = useState([]);
 const [maleProducts, setMaleProducts] = useState([]);
 const [femaleProducts, setFemaleProducts] = useState([]);
 const [paginationA, setPaginationA] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [paginationM, setPaginationM] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [paginationW, setPaginationW] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const clickRef = useRef(null);
 const [title, setTitle] = useState("");
 const [gender, setGender] = useState("");
 const [image, setImage] = useState(null);
 const [price, setPrice] = useState("");
 const [link, setLink] = useState("");
 const [query, setQuery] = useState("");
 const [results, setResults] = useState([]);
 const [isLoading, setIsLoading] = useState(false);

 const {
  address,

  isConnected,
  user,
  apiURL,
  domain,
  setLoading,
  settings,
 } = useContext(Context);

 const getProducts = async (page = 1, limit = 15) => {
  setLoading(true);
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_products", page: page, limit: limit }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setProducts(data.records);
    setPaginationA(data.pagination);

    console.log("All products:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getProducts(1);
 }, []);

 const getMaleProducts = async (page = 1, limit = 15) => {
  setLoading(true);

  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_products", gender: "male", page: page, limit: limit }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setMaleProducts(data.records);
    console.log("Male products:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getMaleProducts(1);
 }, []);

 const getFemaleProducts = async (page = 1, limit = 15) => {
  setLoading(true);

  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "get_products",
     gender: "female",
     page: page,
     limit: limit,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setFemaleProducts(data.records);
    console.log("FEMale products:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getFemaleProducts(1);
 }, []);

 const postProduct = async () => {
  if (!isConnected || user.is_admin !== "yes") {
   showAlert({
    title: "Error!",
    text: "Please connect to an admin wallet",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  if (!title || !gender || !link || image == null) {
   showAlert({
    title: "Error!",
    text: "Please complete all required fields.",
    icon: "error",
    confirmButtonText: "Ok",
   });
   return;
  }

  const formData = new FormData();
  formData.append("image", image);
  formData.append("title", title);
  formData.append("gender", gender);
  formData.append("price", price ? price : 0);
  formData.append("link", link);

  formData.append("action", "post_product");
  setLoading(true);

  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   });
   if (response.data.success) {
    showAlert({
     title: "SUCCESS!",
     text: "Product uploaded successfully.",
     icon: "success",
     confirmButtonText: "Ok",
    });
    await getProducts();
    await getFemaleProducts();
    await getMaleProducts();
   } else {
    showAlert({
     title: "Error!",
     text: response.data.error,
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   console.error("Error uploading the file:", error);
   //  alert("Error uploading the file.");
  }
  setLoading(false);
 };

 useEffect(() => {
  if (query.length > 0) {
   const fetchData = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("query", query);
    //formData.append("type", type);

    formData.append("action", "search_products");
    try {
     const response = await axios.post(apiURL, formData, {
      headers: {
       "Content-Type": "application/x-www-form-urlencoded",
      },
     });
     setResults(response.data);
    } catch (error) {
     console.error("Error fetching search results", error);
    }
    setIsLoading(false);
   };

   const debounceTimeout = setTimeout(() => {
    fetchData();
   }, 300); // Adjust the debounce delay as needed

   return () => clearTimeout(debounceTimeout);
  } else {
   setResults([]);
  }
 }, [query]);

 return (
  <div>
   <audio ref={clickRef} id="click-sound" src="assets/audio/click.mp3"></audio>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: `url(${domain}assets/images/nft-bg4.jpeg)`,
        }}
       >
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <div className="row">
          <div className="col-md-12">
           <h1 style={{ fontWeight: 800 }}>
            Discover &amp; Shop Adult Gadgets At Ridiculously Low Prices
           </h1>
           <div className="description">
            <span style={{ fontWeight: 700 }}>
             VR glasses, adult toys or other sex gadgets? We've got them all! We have handpicked
             some exciting products for you and they go for ridiculously low prices
            </span>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="site-content layout-1">
       <div className="container">
        {isConnected && user.is_admin === "yes" && (
         <div className="row">
          <div className="col-md-4">
           <input
            placeholder="Product title"
            className="form-control my-5"
            type="text"
            value={title}
            onInput={(e) => setTitle(e.target.value)}
           />
          </div>
          <div className="col-md-4 my-5">
           <select onChange={(e) => setGender(e.target.value)}>
            <option selected> Select a gender</option>
            <option value="all">All</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
           </select>
          </div>
          <div className="col-md-4">
           <input
            value={price}
            onInput={(e) => setPrice(e.target.value)}
            placeholder="Product price"
            className="form-control my-5"
            type="number"
            min={1}
           />
          </div>
          <div className="col-md-6 my-2">
           <input
            // value={image}
            onInput={(e) => setImage(e.target.value)}
            value={image}
            placeholder="Product image"
            className="form-control "
            type="url"
           />
          </div>
          <div className="col-md-6 my-2">
           <input
            value={link}
            onInput={(e) => setLink(e.target.value)}
            placeholder="Product link"
            className="form-control"
            type="url"
           />
          </div>
          <div className="col-md-12 my-4">
           <div className="text-center">
            <button onClick={postProduct} className="btn btn-custom">
             Submit
            </button>
           </div>
          </div>
         </div>
        )}
        <div className="row">
         <div className="col-md-2" />
         <div className="col-md-8">
          <input
           placeholder="Search products by name"
           className="form-control my-5"
           style={{
            backgroundColor: "#000",
            color: "#fff",
            width: "100%",
            height: "60px",
            border: "#aaa 1px solid",
           }}
           type="search"
           value={query}
           onChange={(e) => setQuery(e.target.value)}
          />
          {!isLoading && (
           <ul>
            {results.length > 0 &&
             results.map((p, index) => (
              <li key={index} className="my-2 search-hover">
               <a href={p.link} style={{ color: "#fff" }} target="_blank">
                <div className="text-left ml-5">
                 <img src={p.image} alt="Product" width="50" className="mr-2" />
                 {p.title}
                </div>
               </a>
              </li>
             ))}
           </ul>
          )}
          {isLoading && <p>Loading...</p>}
         </div>
         <div className="col-md-2" />
        </div>
       </div>
       <section
        className="py-5 px-3 overlay-section"
        style={{
         backgroundImage: 'url("assets/images/nft-bg5.jpeg")',
         backgroundRepeat: "no-repeat",
         backgroundAttachment: "fixed",
         backgroundSize: "cover",
        }}
       >
        <div className="content">
         <ul className="nav nav-tabs" id="nftTab" role="tablist">
          <li className="nav-item">
           <a
            className="nav-link active"
            id="all-tab"
            data-toggle="tab"
            href="#all"
            role="tab"
            aria-controls="all"
            aria-selected="true"
           >
            ALL
           </a>
          </li>
          <li className="nav-item">
           <a
            className="nav-link"
            id="female-tab"
            data-toggle="tab"
            href="#female"
            role="tab"
            aria-controls="female"
            aria-selected="false"
           >
            Female
           </a>
          </li>
          <li className="nav-item">
           <a
            className="nav-link"
            id="male-tab"
            data-toggle="tab"
            href="#male"
            role="tab"
            aria-controls="male"
            aria-selected="false"
           >
            Male
           </a>
          </li>
         </ul>
         <div className="tab-content" id="nftTabContent">
          <div
           className="tab-pane fade show active"
           id="all"
           role="tabpanel"
           aria-labelledby="new-arrivals-tab"
          >
           <div className="row mt-4">
            {products.length > 0 ? (
             products.map((p, i) => (
              <div className="col-md-4" key={i}>
               <div className="card nft-card">
                <img src={p.image} className="card-img-top" alt="Preatent" />
                <div className="card-body">
                 <div className="d-flex justify-content-between align-items-center"></div>
                 <h5 className="card-title">{p.title}</h5>

                 <p className="price">${formatNumber(p.price)}</p>
                 <div className="text-center">
                  <a href={p.link} target="_blank" className="btn btn-custom">
                   View item
                  </a>
                 </div>
                </div>
               </div>
              </div>
             ))
            ) : (
             <div className="text-center">
              <img src="assets/images/fly.gif" height={100} />
              <p>No products here yet...</p>
             </div>
            )}

            {/* Repeat for other cards */}
           </div>
           {products.length > 0 && (
            <Pagination
             currentPage={paginationA.current_page}
             totalPages={paginationA.total_pages}
             onPageChange={(page) => getProducts(page)}
            />
           )}
          </div>
          <div className="tab-pane fade" id="female" role="tabpanel" aria-labelledby="female-tab">
           <div className="row mt-4">
            {femaleProducts.length > 0 ? (
             femaleProducts.map((p, i) => (
              <div className="col-md-4" key={i}>
               <div className="card nft-card">
                <img src={p.image} className="card-img-top" alt="Preatent" />
                <div className="card-body">
                 <div className="d-flex justify-content-between align-items-center"></div>
                 <h5 className="card-title">{p.title}</h5>

                 <p className="price">${formatNumber(p.price)}</p>
                 <div className="text-center">
                  <a href={p.link} target="_blank" className="btn btn-custom">
                   View item
                  </a>
                 </div>
                </div>
               </div>
              </div>
             ))
            ) : (
             <div className="text-center">
              <img src="assets/images/fly.gif" height={100} />
              <p>No products here yet...</p>
             </div>
            )}

            {/* Repeat for other cards */}
           </div>
           {femaleProducts.length > 0 && (
            <Pagination
             currentPage={paginationW.current_page}
             totalPages={paginationW.total_pages}
             onPageChange={(page) => getFemaleProducts(page)}
            />
           )}
          </div>
          <div className="tab-pane fade" id="male" role="tabpanel" aria-labelledby="male-tab">
           <div className="row mt-4">
            {maleProducts.length > 0 ? (
             maleProducts.map((p, i) => (
              <div className="col-md-4" key={i}>
               <div className="card nft-card">
                <img src={p.image} className="card-img-top" alt="Preatent" />
                <div className="card-body">
                 <div className="d-flex justify-content-between align-items-center"></div>
                 <h5 className="card-title">{p.title}</h5>

                 <p className="price">${formatNumber(p.price)}</p>
                 <div className="text-center">
                  <a href={p.link} target="_blank" className="btn btn-custom">
                   View item
                  </a>
                 </div>
                </div>
               </div>
              </div>
             ))
            ) : (
             <div className="text-center">
              <img src="assets/images/fly.gif" height={100} />
              <p>No products here yet...</p>
             </div>
            )}

            {/* Repeat for other cards */}
           </div>
           {maleProducts.length > 0 && (
            <Pagination
             currentPage={paginationM.current_page}
             totalPages={paginationM.total_pages}
             onPageChange={(page) => getMaleProducts(page)}
            />
           )}
          </div>
         </div>
        </div>
       </section>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default Shop;
