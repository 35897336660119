import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import { formatNumber, formatLocalTime, shortenAddress } from "../../utils/helpers";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import Pagination2 from "../../components/Pagination2";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function WatchHome() {
 const [newVideos, setNewVideos] = useState([]);
 const [popularVideos, setPopularVideos] = useState([]);
 const [popularCategories, setPopularCategories] = useState([]);
 const [pagination, setPagination] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [video, setVideo] = useState([]);

 const { apiURL, domain, setLoading } = useContext(Context);

 const getVideo = async (page = 1, limit = 7) => {
  setLoading(true);

  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "get_latest_watch_videos",
     page: page,
     limit: limit,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setVideo(data.records);
    setPagination(data.pagination);
    // console.log("Fetched Video URL:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getVideo(1);
 }, []);

 const getNewVideos = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_new_watch_videos" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setNewVideos(data);
    console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getNewVideos();
 }, []);

 const getPopularVideos = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_popular_watch_videos" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setPopularVideos(data);
    //  console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getPopularVideos();
 }, []);

 const getPopularCategories = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_popular_watch_categories" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setPopularCategories(data);
    //  console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getPopularCategories();
 }, []);

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div
     id="main-content"
     style={{
      backgroundColor: "#1e1e1e",
     }}
    >
     <div
      className="thim-banner_home-1"
      style={{ backgroundImage: "url(assets/images/cover2.jpeg)" }}
     >
      <div className="overlay-area" />
      <div className="container-fluid">
       <div className="bp-element bp-element-st-list-videos vblog-layout-1">
        <div className="wrap-element">
         <div className="feature-item">
          {/* Slider main container */}

          <Swiper
           navigation={true}
           modules={[Pagination, EffectFade, Autoplay, Navigation]}
           autoplay={{ delay: 4000 }}
           effect="fade"
           pagination={{ clickable: true }}
           slidesPerView={1}
           centeredSlides={true}
           fadeEffect={{
            crossFade: true,
           }}
           speed={800}
          >
           {newVideos.length > 0 &&
            newVideos.map((v, i) => (
             <SwiperSlide key={`video${i}`}>
              {" "}
              <div className="row">
               <div className="col-lg-7">
                <div className="video">
                 <img
                  className="slida-img"
                  src={domain + v.cover_photo}
                  alt="IMG"
                  style={{
                   objectFit: "cover",
                   height: "400px",
                  }}
                 />
                 <div className="overlay" />
                 <div className="meta-info">
                  <div className="imdb">
                   <span className="value">{Math.round(formatNumber(v.views))}</span>
                   VIEWS
                  </div>
                  <div className="label">{v.category}</div>
                 </div>
                 <a href={`single-video?vid=${v.id}`} className="btn-play"></a>
                </div>
               </div>
               <div className="col-lg-5">
                <div className="text">
                 <h4 className="title">
                  <a href={`single-video?vid=${v.id}`}>{v.title}</a>
                 </h4>
                 <div className="info">
                  <span className="item-info">
                   BY{" "}
                   <a href={`profile?user=${!v.username ? v.poster : v.username}`}>
                    {!v.username ? shortenAddress(v.poster) : v.username}{" "}
                    {v.verified === "yes" && (
                     <i className="fa fa-check-circle" aria-hidden="true" />
                    )}
                   </a>
                  </span>
                  <span className="item-info">{formatLocalTime(v.post_date)}</span>
                  <span className="item-info">{v.category}</span>
                 </div>
                 <div className="description">{v.description}</div>
                 <div className="row text-center">
                  <div className="col-6">
                   <a
                    href={`single-video?vid=${v.id}`}
                    className="btn-readmore btn-normal shape-round btn-custom"
                   >
                    Watch Now
                   </a>
                  </div>
                  <div className="col-6">
                   <a href="/post-video" className="btn-readmore btn-normal shape-round btn-custom">
                    Submit Video
                   </a>
                  </div>
                 </div>
                </div>
               </div>
              </div>
             </SwiperSlide>
            ))}
          </Swiper>
         </div>
        </div>
       </div>
      </div>
     </div>
     {/*POPULAR-*/}

     <div className="thim-popular-video_home-1" style={{ marginTop: "0px" }}>
      <div className="container-fluid px-sm-0 px-md-5 px-lg-5">
       <div className="bp-element bp-element-st-list-videos vblog-layout-slider-1">
        <div className="wrap-element">
         <div className="heading-post">
          <h3 className="title">popular videos</h3>
         </div>
         <div className="list-posts">
          <Swiper
           navigation={true}
           modules={[Pagination, Autoplay, Navigation]}
           autoplay
           //effect="fade"
           pagination={{ clickable: true }}
           // slidesPerView={3}
           loop={true}
           breakpoints={{
            640: {
             slidesPerView: 1,
             spaceBetween: 20,
            },
            768: {
             slidesPerView: 2,
             spaceBetween: 30,
            },
            1024: {
             slidesPerView: 3,
             spaceBetween: 30,
            },
           }}
           className="my-5"
          >
           {popularVideos.length > 0 &&
            popularVideos.map((v, i) => (
             <SwiperSlide key={`p-video${i}`}>
              {" "}
              <div className="item-slick">
               <div className="post-item">
                <div className="pic">
                 <img
                  src={domain + v.cover_photo}
                  alt="IMG"
                  style={{
                   height: "200px",
                   objectFit: "cover",
                  }}
                 />
                 <div className="overlay" />
                 <div className="meta-info">
                  <div className="imdb">
                   <span className="value">{v.views}</span>
                   VIEWS
                  </div>
                  <div className="label" style={{ background: "#e40914" }}>
                   {v.category}
                  </div>
                 </div>
                 <a href={`single-video?vid=${v.id}`} className="btn-play">
                  {" "}
                 </a>
                </div>
                <h4 className="title">
                 <a href={`single-video?vid=${v.id}`}>{v.title}</a>
                </h4>
                <div className="info">{v.tags}</div>
               </div>
              </div>
             </SwiperSlide>
            ))}
          </Swiper>
         </div>
        </div>
       </div>
      </div>
     </div>
     {/*ADS*/}
     <div className="thim-ads_home-1">
      <div className="container-fluid">
       <div className="bp-element bp-element-call-to-action vblog-layout-1">
        <div className="wrap-element" style={{ backgroundImage: "url(assets/images/ads-01.jpg)" }}>
         <div className="overlay" />
         {/*}
                  <a href="#" className="content">
                    <div className="text">
                      PORN GAME SHOW Horny Girls Collection Hangout
                    </div>
                    <div className="btn-readmore btn-small shape-round">
                      read more
                    </div>
                  </a>
                  */}
        </div>
       </div>
      </div>
     </div>
     {/*CATEGORIES*/}
     <div className="thim-trending-categories_home-1">
      <div className="container-fluid">
       <div className="bp-element bp-element-st-list-categories vblog-layout-slider-1">
        <div className="wrap-element">
         <div className="heading-post">
          <div className="text">
           <h3 className="title">TRENDING CATEGORIES</h3>
           <a href="watch-categories" className="link">
            SEE ALL CATEGORIES
           </a>
          </div>
         </div>
         <div className="list-posts">
          <Swiper
           navigation={true}
           modules={[Pagination, Autoplay, Navigation]}
           autoplay
           //effect="fade"
           pagination={{ clickable: true }}
           // slidesPerView={3}
           loop={true}
           breakpoints={{
            640: {
             slidesPerView: 1,
             spaceBetween: 20,
            },
            768: {
             slidesPerView: 2,
             spaceBetween: 30,
            },
            1024: {
             slidesPerView: 4,
             spaceBetween: 30,
            },
           }}
           className="my-5"
          >
           {popularCategories.length > 0 &&
            popularCategories.map((c, i) => (
             <SwiperSlide key={`p-cats${i}`}>
              {" "}
              <div className="post-item">
               <img src={`assets/images/categories/${c.image}`} alt="IMG" />
               <div className="overlay" />
               <a href={`categories?cat=${c.category}`} className="content">
                <span className="title">{c.category}</span>
                {Math.floor(formatNumber(c.post_count))} {c.post_count > 1 ? "Videos" : "Video"}
               </a>
              </div>
             </SwiperSlide>
            ))}
          </Swiper>
         </div>
        </div>
       </div>
      </div>
     </div>
     {/*LATEST VIDEOS*/}
     <div className="thim-latest-video_home-1">
      <div className="container">
       <div className="bp-element bp-element-st-list-videos vblog-layout-grid-1">
        <div className="wrap-element">
         <div className="heading-post">
          <div className="wrap-title">
           <h3 className="title">LATEST VIDEOS</h3>
          </div>
         </div>
         {video.length > 0 && (
          <>
           <div className="row">
            <div className="col-md-5">
             <div className="item-slick" style={{ cursor: "pointer" }}>
              <div className="post-item">
               <div className="pic" style={{ position: "relative", height: "400px" }}>
                <img
                 src={domain + video[0].cover_photo}
                 alt="IMG"
                 className="vr-slidera"
                 style={{ objectFit: "cover" }}
                 height={400}
                />

                <div
                 className="overlay"
                 style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.4)", // Semi-transparent overlay
                  zIndex: 2,
                 }}
                 onClick={() => window.open(`single-video?vid=${video[0].id}`, "_blank")}
                />
                <div
                 className="meta-info"
                 style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  color: "#fff",
                  zIndex: 2,
                 }}
                >
                 <div className="imdb">
                  <span className="value">{video[0].views}</span> VIEWS
                 </div>
                </div>
                <div
                 style={{
                  position: "absolute",
                  top: "50%",
                  left: "0%",
                  transform: "translate(-0%, -50%)",
                  color: "#fff",
                  textAlign: "center",
                  padding: "0 10px",
                  zIndex: 2,
                 }}
                 onClick={() => window.open(`single-video?vid=${video[0].id}`, "_blank")}
                >
                 <h4 className="title">{video[0].title}</h4>
                </div>
                <div
                 className="label"
                 style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  background: "#e40914",
                  padding: "5px 10px",
                  color: "#fff",
                  borderRadius: "5px",
                  zIndex: 2,
                 }}
                >
                 {video[0].category}
                </div>
               </div>
              </div>
             </div>
            </div>
            <div className="col-md-7">
             <div className="row">
              {video.slice(1).map((v, i) => (
               <div key={i} className="col-md-6 my-2">
                <div className="item-slick" style={{ cursor: "pointer" }}>
                 <div className="post-item">
                  <div className="pic" style={{ position: "relative" }}>
                   <img src={domain + v.cover_photo} alt="IMG" className="vr-slider" />

                   <div
                    className="overlay"
                    style={{
                     position: "absolute",
                     top: 0,
                     left: 0,
                     width: "100%",
                     height: "100%",
                     background: "rgba(0, 0, 0, 0.4)", // Semi-transparent overlay
                     zIndex: 2,
                    }}
                    onClick={() => window.open(`single-video?vid=${v.id}`, "_blank")}
                   />
                   <div
                    className="meta-info"
                    style={{
                     position: "absolute",
                     top: "10px",
                     left: "10px",
                     color: "#fff",
                     zIndex: 2,
                    }}
                   >
                    <div className="imdb">
                     <span className="value">{v.views}</span> VIEWS
                    </div>
                   </div>
                   <div
                    style={{
                     position: "absolute",
                     top: "50%",
                     left: "0%",
                     transform: "translate(-0%, -50%)",
                     color: "#fff",
                     textAlign: "center",
                     padding: "0 10px",
                     zIndex: 2,
                    }}
                    onClick={() => window.open(`single-video?vid=${v.id}`, "_blank")}
                   >
                    <h4 className="title">{v.title}</h4>
                   </div>
                   <div
                    className="label"
                    style={{
                     position: "absolute",
                     bottom: "10px",
                     right: "10px",
                     background: "#e40914",
                     padding: "5px 10px",
                     color: "#fff",
                     borderRadius: "5px",
                     zIndex: 2,
                    }}
                   >
                    {v.category}
                   </div>
                  </div>
                 </div>
                </div>
               </div>
              ))}
             </div>
            </div>
           </div>
           {video.length > 0 && (
            <Pagination2
             currentPage={pagination.current_page}
             totalPages={pagination.total_pages}
             onPageChange={(page) => getVideo(page)}
            />
           )}
          </>
         )}
        </div>
       </div>
      </div>
     </div>
     {/*ADS*/}
     <div className="thim-ads_home-1">
      <div className="container-fluid">
       <div className="bp-element bp-element-call-to-action vblog-layout-2">
        <div className="wrap-element" style={{ backgroundImage: "url(assets/images/ads-01.jpg)" }}>
         <div className="overlay" />
         {/*
                 <a href="#" className="content">
                    <div className="text">
                      THIS IS ANOTHER ADS that leads to no where
                    </div>
                  </a>
                  */}
        </div>
       </div>
      </div>
     </div>
    </div>
    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default WatchHome;
