import { ethers, Contract, JsonRpcProvider } from "ethers";
import abi from "./controller.json";
import nftABI from "./nft.json";

import { Context } from "../context/contex";

export async function ReadContract() {
  const controlContract = "0x0F800f2894780e3AbB881c19a3c2040d5BcB4f91";

  const provider = new JsonRpcProvider(
    "https://data-seed-prebsc-1-s1.binance.org:8545"
  );

  //const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/");

  const contract = new Contract(controlContract, abi, provider);
  // console.log("CONTRACTA", contract);
  return contract;
}

export async function NftContract() {
  const controlContract = "0xEa3d89683BEEb93D600Ba42128f409dA899DfFe5";

  const provider = new JsonRpcProvider(
    "https://data-seed-prebsc-1-s1.binance.org:8545"
  );

  //const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/");

  const contract = new Contract(controlContract, nftABI, provider);
  // console.log("CONTRACTA", contract);
  return contract;
}
