/* global AFRAME */
import React, { useEffect, useState, useContext, useRef } from "react";
import "aframe";
import { Context } from "../context/contex";
import axios from "axios";
import VideoPlayer from "./VRPlayer2";
import { showAlert } from "../utils/helpers";

// Register A-Frame components
if (typeof AFRAME !== "undefined") {
  AFRAME.registerComponent("poster-link", {
    schema: { url: { type: "string" } },
    init: function () {
      this.el.addEventListener("click", () => {
        window.open(this.data.url, "_blank");
      });
    },
  });

  AFRAME.registerComponent("hover-sound", {
    init: function () {
      const soundEl = document.querySelector("#hover-sound");
      const playSound = () => {
        soundEl.currentTime = 0;
        soundEl.play();
      };
      this.el.addEventListener("mouseenter", playSound);
      this.el.addEventListener("mouseleave", playSound);
    },
  });

  AFRAME.registerComponent("zoom-controls", {
    schema: {
      minDistance: { type: "number", default: 10 },
      maxDistance: { type: "number", default: 75 },
      zoomSpeed: { type: "number", default: 0.1 },
    },
    init: function () {
      this.cameraEl = this.el.sceneEl.camera.el;
      this.camera = this.cameraEl.getObject3D("camera");
      this.zoomLevel = this.camera.fov; // Assuming a perspective camera
      this.handleWheel = this.handleWheel.bind(this);
      this.handlePinchStart = this.handlePinchStart.bind(this);
      this.handlePinchMove = this.handlePinchMove.bind(this);
      this.handlePinchEnd = this.handlePinchEnd.bind(this);

      this.pinchStartDistance = null;
      this.initialZoomLevel = null;

      window.addEventListener("wheel", this.handleWheel);
      this.el.sceneEl.addEventListener("touchstart", this.handlePinchStart, {
        passive: false,
      });
      this.el.sceneEl.addEventListener("touchmove", this.handlePinchMove, {
        passive: false,
      });
      this.el.sceneEl.addEventListener("touchend", this.handlePinchEnd);
    },
    handleWheel: function (event) {
      this.zoomLevel += event.deltaY * this.data.zoomSpeed;
      this.zoomLevel = Math.max(
        this.data.minDistance,
        Math.min(this.data.maxDistance, this.zoomLevel)
      );
      this.camera.fov = this.zoomLevel;
      this.camera.updateProjectionMatrix();
    },
    handlePinchStart: function (event) {
      if (event.touches.length === 2) {
        event.preventDefault();
        this.pinchStartDistance = this.getPinchDistance(event.touches);
        this.initialZoomLevel = this.zoomLevel;
      }
    },
    handlePinchMove: function (event) {
      if (event.touches.length === 2) {
        event.preventDefault();
        const currentDistance = this.getPinchDistance(event.touches);
        const zoomChange =
          (this.pinchStartDistance - currentDistance) * this.data.zoomSpeed;
        this.zoomLevel = Math.max(
          this.data.minDistance,
          Math.min(this.data.maxDistance, this.initialZoomLevel + zoomChange)
        );
        this.camera.fov = this.zoomLevel;
        this.camera.updateProjectionMatrix();
      }
    },
    handlePinchEnd: function (event) {
      if (event.touches.length < 2) {
        this.pinchStartDistance = null;
        this.initialZoomLevel = null;
      }
    },
    getPinchDistance: function (touches) {
      const dx = touches[0].clientX - touches[1].clientX;
      const dy = touches[0].clientY - touches[1].clientY;
      return Math.sqrt(dx * dx + dy * dy);
    },
    remove: function () {
      window.removeEventListener("wheel", this.handleWheel);
      this.el.sceneEl.removeEventListener("touchstart", this.handlePinchStart);
      this.el.sceneEl.removeEventListener("touchmove", this.handlePinchMove);
      this.el.sceneEl.removeEventListener("touchend", this.handlePinchEnd);
    },
  });
}

const VRGallery = () => {
  const [categories, setCategories] = useState([]);
  const [posters, setPosters] = useState([]);

  const [watched, setWatched] = useState(false);
  const [isVR, setIsVR] = useState(false);

  const videoRef = useRef(null);
  const [aspectRatio, setAspectRatio] = useState(
    window.innerWidth / window.innerHeight
  );
  const [isMobile, setIsMobile] = useState(false);
  const [isVRSupported, setIsVRSupported] = useState(false);

  const {
    address,
    token,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
    domain,
    loading,
    setLoading,
    togglePlay,
    isPlaying,
    setIsPlaying,
    videoId,
    setVideoId,
    videoOwner,
    setVideoOwner,
    activeScene,
    setActiveScene,
    currentVideo,
    setCurrentVideo,
    handlePosterClick,
    intro,
    setIntro,
  } = useContext(Context);

  const blank = [
    {
      id: "0",

      cover_photo: "assets/images/no-video.png",
    },
  ];

  useEffect(() => {
    // Check VR support
    const checkVRSupport = async () => {
      if ("xr" in navigator) {
        try {
          const supported = await navigator.xr.isSessionSupported(
            "immersive-vr"
          );
          setIsVRSupported(supported);
        } catch (error) {
          console.error("Error checking VR support:", error);
          setIsVRSupported(false);
        }
      } else {
        setIsVRSupported(false);
      }
    };

    // Check if device is mobile
    const checkMobileDevice = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const mobileRegex =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      setIsMobile(mobileRegex.test(userAgent));
    };

    // Run both checks
    checkVRSupport();
    checkMobileDevice();

    // Optionally, you can add an event listener to recheck mobile on resize
    window.addEventListener("resize", checkMobileDevice);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", checkMobileDevice);
    };
  }, []);

  const getWatched = async (vid, wallet) => {
    if (!wallet || !vid) return;
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "get_watched",
          vid: vid,
          wallet: wallet,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setWatched(data.exists);
      //  console.log("Alive Numbers:", data.watched);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getWatched(videoId, address);
  }, [videoId, address]);

  const payReward = async (connected, wallet, watched, owner, id) => {
    setIsPlaying(false);
    if (!connected || !wallet || watched || wallet == owner) return;
    setLoading(true);

    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          user: wallet,
          vid: id,
          action: "insert_watch_record",
          poster: owner,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        console.error("Error:", data.message);
        showAlert({
          title: "Error!",
          text: data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        showAlert({
          title: "CONGRATS!",
          text: data.message,
          icon: "success",
          confirmButtonText: "Sounds good",
        });

        setWatched(true);
        // console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            action: "get_all_categories_with_images",
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.error) {
          console.error("Error:", data.error);
        } else {
          setCategories(data);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchCategories();
  }, []);

  const fetchPosters = async (cat) => {
    setLoading(true);
    setPosters([]);
    try {
      const response = await axios.post(
        apiURL,
        new URLSearchParams({
          action: "get_vr_category_videos",
          category: cat,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data.error) {
        setPosters(blank);
      } else {
        setPosters(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching posters:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("posters:", posters);
  }, [posters]);

  const createCategoryPoster = (category, index) => {
    const radius = 20; // Radius of the circle
    const columns = 15; // Number of columns on each side
    const rows = Math.ceil(categories.length / (columns * 2)); // Number of rows needed
    const columnIndex = index % columns; // Column index for the current category
    const rowIndex = Math.floor(index / (columns * 2)); // Row index for the current category
    const side = index % (columns * 2) < columns ? 1 : -1; // Determine side (left or right)
    const x = side * radius;
    const y = 2 - rowIndex * 4; // Vertical spacing between rows
    const z = -5 - columnIndex * 4; // Horizontal spacing between columns

    return (
      <a-entity
        key={index}
        position={`${x} ${y} ${z}`}
        rotation={`0 ${side === 1 ? -30 : 30} 0`}
      >
        <a-image
          src={`assets/images/categories/${category.image}`}
          width="3"
          height="3"
          class="raycastable"
          //    event-set__mouseenter="scale: 1.2 1.2 1"
          //  event-set__mouseleave="scale: 1 1 1"
          hover-sound
          onClick={() => fetchPosters(category.category)}
          animation="property: opacity; from: 0; to: 1; dur: 1000; delay: ${index * 50}"
        />
        <a-text
          value={category.category}
          align="center"
          position="0 -2 0"
          width="5"
          scale="2 2 2"
          color="white"
        />
      </a-entity>
    );
  };

  const createPoster = (image, index) => {
    const x = ((index % 3) - 1) * 3;
    const y = 2 - Math.floor(index / 3) * 3;
    const z = -5;

    return (
      <a-image
        key={index}
        src={domain + image.cover_photo}
        position={`${x} ${y} ${z}`}
        width="2.5"
        height="3"
        class="raycastable"
        hover-sound
        onClick={() => handlePosterClick(image.video, image.id, image.poster)}
        animation="property: opacity; from: 0; to: 1; dur: 1000; delay: ${index * 50}"
      />
    );
  };

  useEffect(() => {
    console.log("CURRENT", currentVideo);
    console.log("CURRENT id", videoId);
  }, [currentVideo]);

  function toggleFullscreen() {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      setIsVR(false);
    } else {
      // Enter fullscreen
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
      setIsVR(true);
    }
  }

  const toggleVR = () => {
    const scene = document.querySelector("a-scene");
    if (scene.is("vr-mode")) {
      scene.exitVR();
      setIsVR(false);
    } else {
      scene.enterVR();
      setIsVR(true);
    }
  };

  const goBack = () => {
    if (activeScene === "video") {
      setActiveScene("gallery");
    } else {
      setIntro(true);
    }
  };

  return (
    <div id="threeD">
      <a-scene embedded vr-mode-ui="enabled: true">
        <a-assets>
          <audio id="hover-sound" src="assets/audio/over.mp3" preload="auto" />
          {activeScene === "video" && currentVideo && (
            <video
              ref={videoRef}
              id={videoId}
              src={currentVideo}
              //  loop
              crossOrigin="anonymous"
              playsInline
              webkit-playsinline="true"
              style={{ display: "none" }}
              onEnded={() =>
                payReward(isConnected, address, watched, videoOwner, videoId)
              }
            />
          )}
        </a-assets>

        {activeScene === "gallery" ? (
          <>
            <a-sky
              src="https://ai18pluspresale.com/assets/images/pano.jpg"
              rotation="0 -90 0"
            />
            <a-entity id="categories" position="0 1.6 0">
              {categories.map((category, index) =>
                createCategoryPoster(category, index)
              )}
            </a-entity>
            <a-entity id="posters" position="0 1.6 -10">
              {posters.map((poster, index) => createPoster(poster, index))}
            </a-entity>
          </>
        ) : (
          <VideoPlayer
            videoId={videoId}
            onBack={() => setActiveScene("gallery")}
          />
        )}

        <a-entity
          camera
          look-controls
          wasd-controls
          position="0 1.6 0"
          rotation="0 0 0"
          zoom-controls
        >
          <a-entity
            cursor="rayOrigin: mouse"
            raycaster="objects: .raycastable"
          />
        </a-entity>
      </a-scene>

      <div className="text-center vr-btns">
        <button className="vr-btn" onClick={goBack}>
          <i className="fa fa-arrow-left"></i> Back
        </button>
        {activeScene === "video" && (
          <>
            <button className="vr-btn" onClick={() => togglePlay(videoId)}>
              <i className={`fa ${isPlaying ? "fa-pause" : "fa-play"}`}></i>{" "}
              {isPlaying ? "Pause" : "Play"}
            </button>
          </>
        )}
        <button
          className="vr-btn vr-toggle-btn"
          onClick={() => {
            if (isVRSupported) {
              toggleVR();
            } else {
              toggleFullscreen();
            }
          }}
        >
          <i className={`fa ${!isVR ? "fa-arrows-alt" : "fa-compress"}`}></i>{" "}
          {!isVR ? "ENTER VR" : "EXIT VR"}
        </button>
      </div>
    </div>
  );
};

export default VRGallery;
