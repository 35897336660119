import React, { useEffect, useState, useContext } from "react";
import { Context } from "../context/contex";

const VideoPlayer = ({ videoId, onBack }) => {
  const { setIsPlaying } = useContext(Context);
  useEffect(() => {
    const video = document.getElementById(videoId);
    if (video) {
      video
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing video:", error);
        });
    }
  }, [videoId]);

  return (
    <a-entity>
      <a-videosphere
        src={`#${videoId}`}
        radius="10000"
        rotation="0 -90 0"
      ></a-videosphere>
      {/*  <a-video
        src={`#${videoId}`}
        width="16"
        height="9"
        position="0 2 -6"
        rotation="0 -15 0"
      ></a-video>*/}
    </a-entity>
  );
};

export default VideoPlayer;
