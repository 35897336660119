import React, { useState, useContext, useEffect, useRef } from "react";
import "../components/three.css";
import VRGallery from "../components/VRGallery";
import { Context } from "../context/contex";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function VRFull() {
  const [popularVideos, setPopularVideos] = useState([]);

  const clickRef = useRef(null);
  const {
    intro,
    setIntro,
    videoId,
    videoOwner,
    currentVideo,
    activeScene,
    domain,
    handlePosterClick,
    apiURL,
  } = useContext(Context);

  const enterVR = () => {
    clickRef.current.play();
    setIntro(false);
  };

  const getPopularVideos = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_new_vr_watch_videos" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setPopularVideos(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getPopularVideos();
  }, []);

  return (
    <div>
      <audio
        ref={clickRef}
        id="click-sound"
        src="assets/audio/click.mp3"
      ></audio>
      {intro ? (
        <div id="intro" className="intro">
          <img
            className="intro-bg"
            src="assets/images/room-cover.jpg"
            onClick={enterVR}
          />
          <i
            onClick={enterVR}
            className="play-icon fa fa-play-circle-o clickable"
            aria-hidden="true"
          ></i>
        </div>
      ) : (
        <VRGallery />
      )}
      {intro && (
        <>
          <div className="my-4 text-center">
            <h4 style={{ fontSize: "25px" }}>New VR Videos</h4>
          </div>
          <Swiper
            navigation={true}
            modules={[Pagination, Autoplay, Navigation]}
            autoplay
            //effect="fade"
            pagination={{ clickable: true }}
            // slidesPerView={3}
            loop={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            className="my-3"
          >
            {popularVideos.length > 0 &&
              popularVideos.map((v, i) => (
                <SwiperSlide key={`p-video${i}`}>
                  {" "}
                  <div className="item-slick">
                    <div className="post-item">
                      <div className="pic" style={{ position: "relative" }}>
                        <img
                          src={domain + v.cover_photo}
                          alt="IMG"
                          className="vr-slider"
                        />

                        <div
                          className="overlay"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(0, 0, 0, 0.4)", // Semi-transparent overlay
                            zIndex: 2,
                          }}
                          onClick={() =>
                            handlePosterClick(v.video, v.id, v.poster)
                          }
                        />
                        <div
                          className="meta-info"
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                            color: "#fff",
                            zIndex: 2,
                          }}
                        >
                          <div className="imdb">
                            <span className="value">{v.views}</span> VIEWS
                          </div>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "0%",
                            transform: "translate(-0%, -50%)",
                            color: "#fff",
                            textAlign: "center",
                            padding: "0 10px",
                            zIndex: 2,
                          }}
                          onClick={() =>
                            handlePosterClick(v.video, v.id, v.poster)
                          }
                        >
                          <h4 className="title">{v.title}</h4>
                        </div>
                        <div
                          className="label"
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            background: "#e40914",
                            padding: "5px 10px",
                            color: "#fff",
                            borderRadius: "5px",
                            zIndex: 2,
                          }}
                        >
                          {v.category}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </>
      )}
    </div>
  );
}

export default VRFull;
