import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import axios from "axios";
import $ from "jquery";
import { showAlert } from "../../utils/helpers";
import "../styles/post-video.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "./fan.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
const now = Math.floor(Date.now() / 1000);

function PostFanVideo() {
 const {
  address,
  token,
  chainId,
  isConnected,
  walletProvider,
  tokenBalance,
  apiURL,
  user,
  loading,
  setLoading,
 } = useContext(Context);
 const [categories, setCategories] = useState([]);
 const [aiVideos, setAiVideos] = useState([]);
 const [aiVideo, setAiVideo] = useState("");
 const videoRef = useRef(null);
 const canvasRef = useRef(null);
 const [frames, setFrames] = useState([]);
 const [frame, setFrame] = useState(null);

 const [selectedFrame, setSelectedFrame] = useState("");
 const [category, setCategory] = useState("");
 const [title, setTitle] = useState("");
 const [isVR, setIsVR] = useState("no");
 const [isAI, setIsAI] = useState("no");
 const [isPrivate, setIsPrivate] = useState("no");
 const [videoType, setVideoType] = useState("real");
 const [selectedVideo, setSelectedVideo] = useState(null);
 const [selectedImage, setSelectedImage] = useState(null);

 const [description, setDescription] = useState("");
 const [tags, setTags] = useState("");
 const [coverPhoto, setCoverPhoto] = useState(null);
 const [uploadVideo, setUploadVideo] = useState(null);
 const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
 const [uploadVideoPreview, setUploadVideoPreview] = useState(null);
 const [videoDurationValid, setVideoDurationValid] = useState(true);

 const [uploadProgress, setUploadProgress] = useState(0);

 const getCategories = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({ action: "get_categories" }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setCategories(data);
    //  console.log("Alive Numbers:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  getCategories();
 }, []);

 const getAiVideos = async () => {
  try {
   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "get_user_ai_videos",
     wallet: address,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setAiVideos(data);
    console.log("AI VIDEOS:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
 };

 useEffect(() => {
  if (!isConnected) {
   setAiVideos([]);
   setAiVideo("");
   return;
  }
  getAiVideos();
 }, [isConnected, address]);

 const handleCoverPhotoChange = (e) => {
  const file = e.target.files[0];
  if (file) {
   setCoverPhoto(file);
   setCoverPhotoPreview(URL.createObjectURL(file));
  }
 };

 const handleVideoChange = (e) => {
  const file = e.target.files[0];
  if (file && file.type.startsWith("video/")) {
   const videoElement = document.createElement("video");
   videoElement.preload = "metadata";
   videoElement.onloadedmetadata = function () {
    window.URL.revokeObjectURL(videoElement.src);
    const duration = videoElement.duration;
    if (duration > 600) {
     setVideoDurationValid(false);
     setUploadVideo(null);
     setUploadVideoPreview(null);
     return showAlert({
      title: "Error!",
      text: "Video is longer than 10 minutes.",
      icon: "error",
      confirmButtonText: "Ok",
     });
    } else {
     setVideoDurationValid(true);
     setUploadVideo(file);
     setUploadVideoPreview(URL.createObjectURL(file));
    }
   };
   videoElement.src = URL.createObjectURL(file);
  }
 };

 const handleSubmit = async (e) => {
  e.preventDefault();
  if (!isConnected) {
   return showAlert({
    title: "Error!",
    text: "Please connect your wallet and try again.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }
  if (user.creator_type === "both") {
   if (!videoType) {
    return showAlert({
     title: "Error!",
     text: "Please select a video type.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (user.verified !== "yes" && videoType !== "ai") {
    return showAlert({
     title: "Error!",
     text: "You can only post real videos after you pass KYC verification.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (videoType === "real" && uploadVideo == null) {
    return showAlert({
     title: "Error!",
     text: "Please Upload a video first.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (videoType === "real" && !videoDurationValid) {
    return showAlert({
     title: "Error!",
     text: "Please select a video that is less than 10 minutes.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (videoType === "ai" && !aiVideo) {
    return showAlert({
     title: "Error!",
     text: "Please select an Ai video first.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (videoType === "ai" && !frame) {
    return showAlert({
     title: "Error!",
     text: "Please select a cover photo from your Ai video.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  }

  if (user.creator_type === "real") {
   if (user.verified !== "yes") {
    return showAlert({
     title: "Error!",
     text: "You can only post real videos after you pass KYC verification.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (uploadVideo == null) {
    return showAlert({
     title: "Error!",
     text: "Please Upload a video first.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (coverPhoto == null) {
    return showAlert({
     title: "Error!",
     text: "Please Upload a cover photo first.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (!videoDurationValid) {
    return showAlert({
     title: "Error!",
     text: "Please select a video that is less than 10 minutes.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  }

  if (user.creator_type === "ai") {
   if (!aiVideo) {
    return showAlert({
     title: "Error!",
     text: "Please select an Ai video first.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
   if (!frame) {
    return showAlert({
     title: "Error!",
     text: "Please select a cover photo from your Ai video.",
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  }

  if (!title || !description || !tags) {
   return showAlert({
    title: "Error!",
    text: "Please fill all required fields.",
    icon: "error",
    confirmButtonText: "Ok",
   });
  }

  const formData = new FormData();
  formData.append("category", category);
  formData.append("title", title);
  formData.append("isAI", user.creator_type === "ai" || videoType === "ai" ? "yes" : "no");

  formData.append("description", description);
  formData.append(
   "coverPhoto",
   user.creator_type === "ai" || videoType === "ai"
    ? base64ToBlob(frame.split(",")[1], "image/png")
    : coverPhoto,
   "frame.png"
  );
  formData.append(
   "uploadVideo",
   user.creator_type === "ai" || videoType === "ai" ? aiVideo : uploadVideo
  );
  formData.append("tags", tags);
  formData.append("poster", address);

  formData.append("action", "post_fan_video");
  setLoading(true);
  try {
   const response = await axios.post(apiURL, formData, {
    headers: {
     "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
     setUploadProgress(percentCompleted);
    },
   });
   console.log("Form", response);
   if (response.data.success) {
    showAlert({
     title: "Success!",
     text: response.data.message,
     icon: "success",
     confirmButtonText: "Ok",
    });
    setTimeout(() => {
     window.location.href = `/fan-video?vid=${response.data.id}`;
    }, 2000);
   } else {
    showAlert({
     title: "ERROR!",
     text: response.data.message,
     icon: "error",
     confirmButtonText: "Ok",
    });
   }
  } catch (error) {
   console.error("Error:", error);
   // alert(`Error: ${error.message}`);
   // Handle network error
  }
  setLoading(false);
 };

 const handleVideoClick = async (link, index) => {
  setLoading(true);
  setSelectedVideo(index);

  // Download the video file
  const videoFile = await downloadVideo(link);
  if (!videoFile) {
   console.error("Failed to download video");
   setLoading(false);

   return;
  }

  const videoURL = URL.createObjectURL(videoFile);
  const videoElement = videoRef.current;
  videoElement.src = videoURL;
  setAiVideo(link);

  videoElement.onloadedmetadata = () => {
   extractFrames(videoElement);
  };
  setLoading(false);
 };

 const downloadVideo = async (url) => {
  try {
   const response = await fetch(url);
   if (!response.ok) {
    throw new Error("Network response was not ok");
   }
   const blob = await response.blob();
   return blob;
  } catch (error) {
   console.error("Failed to download video:", error);
   return null;
  }
 };

 const extractFrames = (video) => {
  setLoading(true);
  const duration = video.duration;
  if (!duration || isNaN(duration)) {
   console.error("Failed to get video duration");
   return;
  }
  const interval = duration / 10; // Change this to get more or fewer frames
  const framesArray = [];

  for (let i = 0; i <= 10; i++) {
   setTimeout(() => {
    const currentTime = i * interval;
    if (isFinite(currentTime)) {
     video.currentTime = currentTime;
     video.onseeked = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      framesArray.push(canvas.toDataURL());
      if (framesArray.length === 11) {
       setFrames(framesArray);
       setLoading(false);
      }
     };
    } else {
     console.error("Calculated currentTime is not finite", currentTime);
    }
   }, i * 200); // Adjust timeout to ensure the video has time to seek
  }
 };

 const handleFrame = async (frame, index) => {
  setSelectedFrame(index);
  setFrame(frame);
 };

 const base64ToBlob = (base64, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
   const slice = byteCharacters.slice(offset, offset + sliceSize);
   const byteNumbers = new Array(slice.length);
   for (let i = 0; i < slice.length; i++) {
    byteNumbers[i] = slice.charCodeAt(i);
   }
   const byteArray = new Uint8Array(byteNumbers);
   byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
 };

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div id="main-content" style={{ background: "#1e1e1e" }}>
     <div className="content-area">
      <div className="page-title">
       <div
        className="main-top"
        style={{
         backgroundImage: "url(assets/images/bg-page-title.jpg)",
        }}
       >
        <div className="overlay-top-header" />
        <div className="content container" style={{ paddingBottom: "30px" }}>
         <h1>POST A VIDEO</h1>
         <div className="description">Give your active fans something to fantacise about.</div>
        </div>
       </div>
      </div>
      <div className="site-content layout-1">
       <div className="container">
        <div className="form-container">
         {!isConnected ? (
          <div className="alert-warning p-3 my-3 rounded">
           <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Please connect a wallet
           to be able to make a post.
          </div>
         ) : (
          <form onSubmit={handleSubmit}>
           {user.creator_type !== "ai" && (
            <div className="row">
             <div className="col-md-6">
              <div className="form-group">
               <label htmlFor="videoCategory">Video Category</label>
               <select onChange={(e) => setCategory(e.target.value)} required>
                <option>Select a category</option>
                {categories.length > 0 &&
                 categories.map((cat, i) => (
                  <option key={`cat${i}`} value={cat.category}>
                   {cat.category}
                  </option>
                 ))}
               </select>
              </div>
             </div>
             <div className="col-md-6">
              <div className="form-group">
               <label htmlFor="videoTitle">Video Title</label>
               <input
                type="text"
                className="form-control"
                id="videoTitle"
                placeholder="Enter video title"
                onChange={(e) => setTitle(e.target.value)}
                required
               />
              </div>
             </div>

             <div className="col-md-12">
              <div className="form-group">
               <label htmlFor="videoDescription">Video Description</label>
               <textarea
                className="form-control"
                id="videoDescription"
                style={{ height: "120px" }}
                placeholder="Enter video description"
                required
                onChange={(e) => setDescription(e.target.value)}
               />
              </div>
             </div>
             {user.creator_type === "both" && (
              <div className="col-md-12 my-3">
               <div className="row">
                <div className="col-md-6">
                 <label htmlFor="real" className="rcontainer">
                  I am uploading a real video of myself
                  <input
                   id="real"
                   value="real"
                   type="radio"
                   name="creator"
                   onChange={(e) => setVideoType(e.target.value)}
                   checked={videoType === "real"}
                   required
                  />
                  <span className="rcheckmark"></span>
                 </label>
                </div>

                <div className="col-md-6">
                 <label htmlFor="ai" className="rcontainer">
                  I want to upload from my AI videos
                  <input
                   id="ai"
                   type="radio"
                   name="creator"
                   value="ai"
                   onChange={(e) => setVideoType(e.target.value)}
                   checked={videoType === "ai"}
                  />
                  <span className="rcheckmark"></span>
                 </label>
                </div>
               </div>
               {aiVideos.length > 0 && videoType === "ai" && (
                <Swiper
                 navigation={true}
                 modules={[Pagination, Autoplay, Navigation]}
                 // slidesPerView={3}
                 loop={true}
                 breakpoints={{
                  640: {
                   slidesPerView: 2,
                   spaceBetween: 20,
                  },
                  768: {
                   slidesPerView: 4,
                   spaceBetween: 30,
                  },
                  1024: {
                   slidesPerView: 6,
                   spaceBetween: 30,
                  },
                 }}
                 className="my-5"
                >
                 {aiVideos.map((v, index) => (
                  <SwiperSlide key={index}>
                   <div
                    className={`image-container ${selectedVideo === index ? "selected" : ""}`}
                    onClick={() => handleVideoClick(v.link, index)}
                   >
                    <video src={v.link} height={150} controls alt="video placeholder" />
                    <div className="text-center">Select</div>
                   </div>
                  </SwiperSlide>
                 ))}
                </Swiper>
               )}
               {aiVideos.length === 0 && videoType === "ai" && (
                <div className="text-center">
                 <img src="assets/images/fly.gif" height={100} alt="No images" />
                 <p>
                  No Ai videos here yet...{" "}
                  <a className="text-warning" href="/face-swap" target="_blank">
                   Swap some videos to Ai here.
                  </a>
                 </p>
                </div>
               )}
               <div className=" text-center my-5">
                {videoType === "ai" && aiVideo && frames.length > 0 && (
                 <h3 className="mb-3">Click any frame in the video to use as cover photo </h3>
                )}
                <canvas ref={canvasRef} width="320" height="180" style={{ display: "none" }} />
                <div
                 style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                 }}
                >
                 {videoType === "ai" &&
                  aiVideo &&
                  frames.length > 0 &&
                  frames.map((frame, index) => (
                   <img
                    key={index}
                    src={frame}
                    alt={`Frame ${index}`}
                    onClick={() => handleFrame(frame, index)}
                    style={{
                     cursor: "pointer",
                     width: "100px",
                     height: "auto",
                    }}
                    className={`frame-image ${selectedFrame === index ? "selected" : ""}`}
                   />
                  ))}
                </div>
               </div>
              </div>
             )}
             {videoType !== "ai" ? (
              <div className="col-md-6 text-center">
               <div className="form-group">
                <label htmlFor="coverPhoto">
                 <img
                  src={coverPhotoPreview || "./assets/images/ph-image.png"}
                  height={150}
                  alt="cover placeholder"
                 />
                </label>
                <div className="custom-file">
                 <input
                  type="file"
                  className="custom-file-input"
                  id="coverPhoto"
                  accept="image/*"
                  onChange={handleCoverPhotoChange}
                  required
                 />
                 <label className="custom-file-label" htmlFor="coverPhoto">
                  Choose cover photo
                 </label>
                </div>
               </div>
              </div>
             ) : (
              <div className="col-md-6 text-center">
               <div className="form-group">
                <label htmlFor="coverPhoto">
                 <img
                  src={frame || "./assets/images/ph-image.png"}
                  height={150}
                  alt="cover placeholder"
                 />
                </label>
               </div>
              </div>
             )}
             <div className="col-md-6 text-center">
              {videoType !== "ai" ? (
               <div className="form-group">
                <label htmlFor="uploadVideo">
                 <video src={uploadVideoPreview} height={150} controls alt="video placeholder" />
                </label>
                <div className="custom-file">
                 <input
                  type="file"
                  className="custom-file-input"
                  id="uploadVideo"
                  accept="video/mp4"
                  onChange={handleVideoChange}
                  required
                 />
                 <label className="custom-file-label" htmlFor="uploadVideo">
                  Choose video file
                 </label>
                </div>
               </div>
              ) : (
               <video ref={videoRef} height={150} controls alt="video placeholder" />
              )}
             </div>
             <div className="col-md-12">
              <div className="form-group">
               <label htmlFor="videoTags">Tags</label>
               <input
                type="text"
                className="form-control"
                id="videoTags"
                placeholder="Enter tags separated by commas"
                onChange={(e) => setTags(e.target.value)}
                required
               />
              </div>
             </div>
             {uploadProgress > 0 && (
              <div className="col-md-12 my-4">
               <div className="progress">
                <div
                 className="progress-bar"
                 role="progressbar"
                 style={{ width: `${uploadProgress}%` }}
                 aria-valuenow={uploadProgress}
                 aria-valuemin="0"
                 aria-valuemax="100"
                >
                 {uploadProgress}%
                </div>
               </div>
              </div>
             )}

             <div className="col-md-12">
              <button type="submit" className="btn btn-custom btn-block">
               Submit
              </button>
             </div>
            </div>
           )}
           {user.creator_type === "ai" && (
            <div className="row">
             <div className="col-md-6">
              <div className="form-group">
               <label htmlFor="videoCategory">Video Category</label>
               <select onChange={(e) => setCategory(e.target.value)} required>
                <option>Select a category</option>
                {categories.length > 0 &&
                 categories.map((cat, i) => (
                  <option key={`cat${i}`} value={cat.category}>
                   {cat.category}
                  </option>
                 ))}
               </select>
              </div>
             </div>
             <div className="col-md-6">
              <div className="form-group">
               <label htmlFor="videoTitle">Video Title</label>
               <input
                type="text"
                className="form-control"
                id="videoTitle"
                placeholder="Enter video title"
                onChange={(e) => setTitle(e.target.value)}
                required
               />
              </div>
             </div>

             <div className="col-md-12">
              <div className="form-group">
               <label htmlFor="videoDescription">Video Description</label>
               <textarea
                className="form-control"
                id="videoDescription"
                style={{ height: "120px" }}
                placeholder="Enter video description"
                required
                onChange={(e) => setDescription(e.target.value)}
               />
              </div>
             </div>
             <div className="col-md-12">
              {aiVideos.length > 0 ? (
               <>
                <div className="text-center">
                 <h1>Select an Ai Video</h1>
                </div>
                <Swiper
                 navigation={true}
                 modules={[Pagination, Autoplay, Navigation]}
                 // slidesPerView={3}
                 loop={true}
                 breakpoints={{
                  640: {
                   slidesPerView: 2,
                   spaceBetween: 20,
                  },
                  768: {
                   slidesPerView: 4,
                   spaceBetween: 30,
                  },
                  1024: {
                   slidesPerView: 6,
                   spaceBetween: 30,
                  },
                 }}
                 className="my-5"
                >
                 {aiVideos.map((v, index) => (
                  <SwiperSlide key={index}>
                   <div
                    className={`image-container ${selectedVideo === index ? "selected" : ""}`}
                    onClick={() => handleVideoClick(v.link, index)}
                   >
                    <video src={v.link} height={150} controls alt="video placeholder" />
                    <div className="text-center">Select</div>
                   </div>
                  </SwiperSlide>
                 ))}
                </Swiper>
               </>
              ) : (
               <div className="text-center">
                <img src="assets/images/fly.gif" height={100} alt="No images" />
                <p>
                 No Ai videos here yet...{" "}
                 <a className="text-warning" href="/face-swap" target="_blank">
                  Swap some videos to Ai here first.
                 </a>
                </p>
               </div>
              )}
             </div>
             <div className="col-md-12 text-center my-5">
              {aiVideo && frames.length > 0 && (
               <h3 className="mb-3">Click any frame in the video to use as cover photo </h3>
              )}
              <canvas ref={canvasRef} width="320" height="180" style={{ display: "none" }} />
              <div
               style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
               }}
              >
               {aiVideo &&
                frames.length > 0 &&
                frames.map((frame, index) => (
                 <img
                  key={index}
                  src={frame}
                  alt={`Frame ${index}`}
                  onClick={() => handleFrame(frame, index)}
                  style={{
                   cursor: "pointer",
                   width: "100px",
                   height: "auto",
                  }}
                  className={`frame-image ${selectedFrame === index ? "selected" : ""}`}
                 />
                ))}
              </div>
             </div>
             <div className="col-md-6 text-center">
              <div className="form-group">
               <label htmlFor="coverPhoto">
                <img
                 src={frame || "./assets/images/ph-image.png"}
                 height={150}
                 alt="cover placeholder"
                />
               </label>
              </div>
             </div>
             <div className="col-md-6">
              <video ref={videoRef} height={150} controls alt="video placeholder" />
             </div>
             <div className="col-md-12">
              <div className="form-group">
               <label htmlFor="videoTags">Tags</label>
               <input
                type="text"
                className="form-control"
                id="videoTags"
                placeholder="Enter tags separated by commas"
                onChange={(e) => setTags(e.target.value)}
                required
               />
              </div>
             </div>
             <div className="col-md-12">
              <button type="submit" className="btn btn-custom btn-block">
               Submit
              </button>
             </div>
            </div>
           )}
          </form>
         )}
        </div>
       </div>
      </div>
     </div>
    </div>

    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default PostFanVideo;
