import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
  getPercentage,
  showAlert,
} from "../../utils/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function SingleVideo() {
  const [video, setVideo] = useState([]);
  const [theVideo, setTheVideo] = useState("");
  const [duration, setDuration] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [watched, setWatched] = useState(false);

  const [userVideos, setUserVideos] = useState([]);
  const [relatedVideos, setRelatedVideos] = useState([]);

  const {
    address,

    isConnected,
    setLoading,
    apiURL,
    settings,
    domain,
  } = useContext(Context);

  const getVideo = async () => {
    let vid;
    try {
      vid = getQueryParam("vid");
      if (vid == null) return;

      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_single_video", vid: vid }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setVideo(data[0]);
        setTheVideo(data[0].video.toString());

        console.log("Fetched Video URL:", data[0].video);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getVideo();
  }, []);

  const handleLoadedMetadata = (event) => {
    const videoElement = event.target;
    const durationInSeconds = videoElement.duration;
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    setDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
  };

  const getUserVideos = async (wallet) => {
    if (!wallet || wallet == undefined) return;
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "get_user_videos",
          wallet: wallet,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setUserVideos(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getUserVideos(video.poster);
  }, [video]);

  const getWatched = async (vid, wallet) => {
    if (!wallet || !vid) return;
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "get_watched",
          vid: vid,
          wallet: wallet,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setWatched(data.exists);
      //  console.log("Alive Numbers:", data.watched);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getWatched(video.id, address);
  }, [video, address]);

  const getRelatedVideos = async (cat) => {
    if (!cat || cat == undefined) return;

    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "get_related_videos",
          category: cat,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setRelatedVideos(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getRelatedVideos(video.category);
  }, [video]);

  const payReward = async (connected, wallet, watched, video) => {
    setIsPlaying(false);
    if (!connected || !wallet || watched || wallet == video.poster) return;
    setLoading(true);
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          user: wallet,
          vid: video.id,
          action: "insert_watch_record",
          poster: video.poster,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        console.error("Error:", data.message);
        showAlert({
          title: "ERROR!",
          text: data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        showAlert({
          title: "GREAT NEWS!",
          text: data.message,
          icon: "success",
          confirmButtonText: "Sounds good",
        });
        setWatched(true);
        // console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setLoading(false);
  };

  const pay = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "pay_watch_record",
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("PAY:", data.message);

      if (!data.success) {
        console.error("Error:", data.message);
        showAlert({
          title: "ERROR!",
          text: data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        showAlert({
          title: "GREAT NEWS!",
          text: data.message,
          icon: "success",
          confirmButtonText: "Sounds good",
        });
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setLoading(false);
  };

  const togglePlay = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{
                  backgroundImage: "url(assets/images/bg-page-title.jpg)",
                }}
              >
                <div className="overlay-top-header" />
                <div
                  className="content container pt-5"
                  style={{ marginBottom: "150px" }}
                >
                  <h1 className="mb-3 text-capitalize">{video.title}</h1>
                  {settings && (
                    <h4 className="mb-3 text-capitalize">
                      You will earn{" "}
                      {formatNumber(
                        getPercentage(
                          settings.watch_reward_tokens,
                          settings.viewers_percent
                        )
                      )}{" "}
                      tokens for watching this video if you meet all of the
                      criteria below:
                    </h4>
                  )}
                  <div
                    className="justify-content-center bg-dark rounded d-flex p-2 border border-info"
                    style={{ width: "100% !important" }}
                  >
                    <span className="mr-2">
                      {!isConnected ? (
                        <i
                          className="fa fa-exclamation-circle text-danger"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-check-circle text-success"
                          aria-hidden="true"
                        ></i>
                      )}{" "}
                      Connect wallet
                    </span>{" "}
                    <span className="mr-2">
                      {watched ? (
                        <i
                          className="fa fa-exclamation-circle text-danger"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-check-circle text-success"
                          aria-hidden="true"
                        ></i>
                      )}{" "}
                      Not already watched
                    </span>
                    <span className="mr-2">
                      {isConnected && address == video.poster ? (
                        <i
                          className="fa fa-exclamation-circle text-danger"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-check-circle text-success"
                          aria-hidden="true"
                        ></i>
                      )}{" "}
                      Does not own this video
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="site-content layout-1">
              <div className="container">
                <div className="row">
                  <main className="site-main col-12">
                    <div className="wrap-main-content">
                      <div className="bl-video-detail">
                        <div className="player-video">
                          <div className="bg-video">
                            <div
                              className="video-container"
                              style={{ position: "relative" }}
                            >
                              {theVideo && (
                                <>
                                  <video
                                    ref={videoRef}
                                    // controls
                                    width="100%"
                                    height="660px"
                                    onLoadedMetadata={handleLoadedMetadata}
                                    onEnded={() =>
                                      payReward(
                                        isConnected,
                                        address,
                                        watched,
                                        video
                                      )
                                    }
                                    onError={(e) =>
                                      console.error("Error loading video:", e)
                                    }
                                  >
                                    <source
                                      src={domain + theVideo}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                  <div
                                    className="play-button-container text-center"
                                    style={{
                                      position: "absolute",
                                      bottom: "10px",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                    }}
                                  >
                                    <button
                                      className="vr-btn"
                                      onClick={() => togglePlay()}
                                    >
                                      <i
                                        className={`fa ${
                                          isPlaying ? "fa-pause" : "fa-play"
                                        }`}
                                      ></i>{" "}
                                      {isPlaying ? "Pause" : "Play"}
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="detail-video">
                          <div className="info-detail sticky-sidebar">
                            <div className="inner-info">
                              <div className="media-video">
                                <div className="pic-video">
                                  <img
                                    src={domain + video.cover_photo}
                                    alt="IMG"
                                    style={{
                                      height: "400px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>

                                <div className="more-info">
                                  <span className="item-info">
                                    <i className="ion ion-eye" />
                                    {Math.floor(formatNumber(video.views))}{" "}
                                    Views
                                  </span>
                                </div>
                              </div>
                              <div className="info-video">
                                <div className="item-info">
                                  <span className="name-item">
                                    {" "}
                                    Posted by:{" "}
                                  </span>
                                  <span className="value-item">
                                    {" "}
                                    <a
                                      href={`profile?user=${
                                        !video.username
                                          ? video.poster
                                          : video.username
                                      }`}
                                    >
                                      {!video.username
                                        ? shortenAddress(video.poster)
                                        : video.username}
                                    </a>{" "}
                                  </span>
                                </div>
                                <div className="item-info">
                                  <span className="name-item">
                                    {" "}
                                    Posted on:{" "}
                                  </span>
                                  <span className="value-item">
                                    {" "}
                                    {formatLocalTime(video.post_date)}{" "}
                                  </span>
                                </div>
                                <div className="item-info">
                                  <span className="name-item"> Runtime: </span>
                                  <span className="value-item">
                                    {" "}
                                    {duration} min{" "}
                                  </span>
                                </div>
                                <div className="item-info">
                                  <span className="name-item"> Type: </span>
                                  <span className="value-item">
                                    {" "}
                                    {`${
                                      video.is_vr == "yes" ? "VR" : "Standard"
                                    } video`}{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-detail">
                            <div className="field-detail">
                              <h3 className="title-field">Video Description</h3>
                              <div className="content-field">
                                <div>{video.description}</div>
                              </div>
                            </div>
                            <div className="field-detail">
                              <h3 className="title-field">
                                Other Videos by{" "}
                                <a
                                  href={`profile?user=${
                                    !video.username
                                      ? video.poster
                                      : video.username
                                  }`}
                                  style={{ color: "red" }}
                                >
                                  {!video.username
                                    ? shortenAddress(video.poster)
                                    : video.username}
                                </a>
                              </h3>
                              <div className="content-field">
                                <Swiper
                                  navigation={true}
                                  modules={[Pagination, Autoplay, Navigation]}
                                  autoplay
                                  //effect="fade"
                                  pagination={{ clickable: true }}
                                  // slidesPerView={3}
                                  loop={true}
                                  breakpoints={{
                                    640: {
                                      slidesPerView: 1,
                                      spaceBetween: 20,
                                    },
                                    768: {
                                      slidesPerView: 2,
                                      spaceBetween: 30,
                                    },
                                    1024: {
                                      slidesPerView: 2,
                                      spaceBetween: 30,
                                    },
                                  }}
                                  className="my-5"
                                >
                                  {userVideos.length > 0 &&
                                    userVideos.map((v, i) => (
                                      <SwiperSlide key={`p-video${i}`}>
                                        {" "}
                                        <div className="item-slick">
                                          <div className="post-item">
                                            <div className="overlay" />
                                            <div className="pic">
                                              <a
                                                href={`${
                                                  v.is_private === "yes"
                                                    ? `fan-video?vid=${v.id}`
                                                    : `single-video?vid=${v.id}`
                                                }`}
                                                className="btn-play"
                                              >
                                                <img
                                                  src={domain + v.cover_photo}
                                                  alt="IMG"
                                                  style={{
                                                    height: "300px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </a>
                                            </div>
                                            <h4 className="title">
                                              <a
                                                href={`${
                                                  v.is_private === "yes"
                                                    ? `fan-video?vid=${v.id}`
                                                    : `single-video?vid=${v.id}`
                                                }`}
                                                className="btn-play"
                                              >
                                                {v.title}{" "}
                                                {v.is_private == "yes" ? (
                                                  <i
                                                    className="fa fa-lock text-danger"
                                                    aria-hidden="true"
                                                  />
                                                ) : (
                                                  <i
                                                    className="fa fa-globe text-success"
                                                    aria-hidden="true"
                                                  />
                                                )}
                                              </a>
                                            </h4>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    ))}
                                </Swiper>
                              </div>
                            </div>

                            <div className="related-blog">
                              <h3 className="related-title">
                                You May Also Like
                              </h3>
                              <div className="wrap-element">
                                <div className="list-posts">
                                  <Swiper
                                    navigation={true}
                                    modules={[Pagination, Autoplay, Navigation]}
                                    autoplay
                                    //effect="fade"
                                    pagination={{ clickable: true }}
                                    // slidesPerView={3}
                                    loop={true}
                                    breakpoints={{
                                      640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                      768: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                      },
                                      1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                      },
                                    }}
                                    className="my-5"
                                  >
                                    {relatedVideos.length > 0 &&
                                      relatedVideos.map((v, i) => (
                                        <SwiperSlide key={`p-video${i}`}>
                                          {" "}
                                          <div className="item-slick">
                                            <div className="post-item">
                                              <div className="pic">
                                                <img
                                                  src={domain + v.cover_photo}
                                                  alt="IMG"
                                                  style={{
                                                    height: "200px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                                <div className="overlay" />
                                                <div className="meta-info">
                                                  <div className="imdb">
                                                    <span className="value">
                                                      {Math.floor(
                                                        formatNumber(v.views)
                                                      )}
                                                    </span>
                                                    VIEWS
                                                  </div>
                                                  <div
                                                    className="label"
                                                    style={{
                                                      background: "#e40914",
                                                    }}
                                                  >
                                                    {v.category}
                                                  </div>
                                                </div>
                                                <a
                                                  href={`${
                                                    v.is_private === "yes"
                                                      ? `fan-video?vid=${v.id}`
                                                      : `single-video?vid=${v.id}`
                                                  }`}
                                                  className="btn-play"
                                                ></a>
                                              </div>
                                              <h4 className="title">
                                                <a
                                                  href={`${
                                                    v.is_private === "yes"
                                                      ? `fan-video?vid=${v.id}`
                                                      : `single-video?vid=${v.id}`
                                                  }`}
                                                  className="btn-play"
                                                >
                                                  {v.title}{" "}
                                                  {v.is_private == "yes" ? (
                                                    <i
                                                      className="fa fa-lock text-danger"
                                                      aria-hidden="true"
                                                    />
                                                  ) : (
                                                    <i
                                                      className="fa fa-globe text-success"
                                                      aria-hidden="true"
                                                    />
                                                  )}
                                                </a>
                                              </h4>
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      ))}
                                  </Swiper>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default SingleVideo;
