import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Pagination from "../../components/Pagination";

import { Context } from "../../context/contex";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
  showAlert,
  searchArrayBool,
} from "../../utils/helpers";
import axios from "axios";

import "../services/services.css";
import usePagination from "../../components/usePagination";
import { context } from "@react-three/fiber";

function ProfileNFT() {
  const [products, setProducts] = useState([]);
  const [maleProducts, setMaleProducts] = useState([]);
  const [femaleProducts, setFemaleProducts] = useState([]);
  const [newNft, setNewNft] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_pages: 1,
    total_records: 0,
  });
  const [popularNft, setPopularNft] = useState([]);
  const [myNft, setMyNft] = useState([]);
  const itemsPerPage = 20;

  const [intro, setIntro] = useState(true);
  const clickRef = useRef(null);
  const [title, setTitle] = useState("");
  const [gender, setGender] = useState("");
  const [image, setImage] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [link, setLink] = useState("");
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
    domain,
    setLoading,
    settings,
    loading,
    user,
    getUser,
    tokenPrice,
  } = useContext(Context);

  const getNewNft = async (page = 1, limit = 52) => {
    setLoading(true);
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "get_new_profile_nft",
          page: page,
          limit: limit,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setNewNft(data.records);
        setPagination(data.pagination);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getNewNft(1);
  }, []);

  const getPopularNft = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_popular_profile_nft" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setPopularNft(data);
        console.log("All top:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPopularNft();
  }, []);

  const getMyNft = async (wallet) => {
    if (!wallet) return;
    setLoading(true);
    try {
      const response = await axios.post(
        apiURL,
        new URLSearchParams({ action: "get_user_profile_nft", wallet: wallet }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const data = response.data;
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setMyNft(data);
        console.log("My nft:", data);
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isConnected) return;
    getMyNft(address);
  }, [isConnected, address]);

  useEffect(() => {
    if (query.length > 0) {
      const fetchData = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("query", query);
        //formData.append("type", type);

        formData.append("action", "search_profile_nft");
        try {
          const response = await axios.post(apiURL, formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
          setResults(response.data);
        } catch (error) {
          console.error("Error fetching search results", error);
        }
        setIsLoading(false);
      };

      const debounceTimeout = setTimeout(() => {
        fetchData();
      }, 300); // Adjust the debounce delay as needed

      return () => clearTimeout(debounceTimeout);
    } else {
      setResults([]);
    }
  }, [query]);

  const handleLike = async (nftId) => {
    if (!nftId || nftId == null) return;
    if (!isConnected) {
      return showAlert({
        title: "ERROR!",
        text: "Connect a wallet to like this NFT.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    try {
      const details = {
        action: "like_profile_nft",
        nft: nftId,
        wallet: address,
      };

      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(details),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        // console.error("NO LIKE:", data.message);
      } else {
        //   await getUser(address);
        await getMyNft(address);
        await getNewNft();
        await getPopularNft();
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const getPercent = (quantity, supply) => {
    const result = (50 / +supply) * +quantity;
    return result % 1 === 0 ? result : result.toFixed(2);
  };

  const buyNft = async (
    name,
    symbol,
    creator,
    nftId,
    quantity,
    price,
    supply,
    owned,
    image
  ) => {
    // Check if wallet is connected
    if (!isConnected) {
      return showAlert({
        title: "Error!",
        text: "Please connect your wallet and try again.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    if (!quantity || isNaN(quantity) || quantity === 0) {
      return showAlert({
        title: "Error!",
        text: `Quantity is invalid.`,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    if (+quantity > +supply || +quantity > +supply - +owned) {
      return showAlert({
        title: "Error!",
        text: "Quantity exceeds available supply.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    if (+user.credits < +quantity * +price) {
      return showAlert({
        title: "Error!",
        text: "You do not have enough credits in your account for this purchase. Please top up and try again or reduce the quantity.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    if (creator === address) {
      return showAlert({
        title: "Error!",
        text: "You cannot buy your own NFT.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    // Construct form data
    const formData = new FormData();
    formData.append("name", name);
    formData.append("symbol", symbol);

    formData.append("wallet", address);
    formData.append("quantity", quantity);
    formData.append("amount", String(+quantity * +price));
    formData.append("percent", getPercent(quantity, supply));
    formData.append("creator", creator);
    formData.append("nft_id", nftId);
    formData.append("image_link", image);

    formData.append("action", "buy_profile_nft");
    setLoading(true);
    try {
      // Debug FormData contents
      console.log("FormData:", [...formData]);

      // Make the API call
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle response
      if (response.data.success) {
        await getUser(address);
        await getMyNft(address);
        await getNewNft();
        await getPopularNft();
        showAlert({
          title: "Successful!",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "Sounds good",
        });
      } else {
        console.log("Error Response:", response);
        showAlert({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Request Error:", error);
      showAlert({
        title: "Error!",
        text: "An error occurred while updating the details. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{
                  backgroundImage: `url(${domain}assets/images/nft-bg3.webp)`,
                }}
              >
                <div
                  className="content container"
                  style={{ paddingBottom: "30px" }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <h1 style={{ fontWeight: "800" }}>
                        Discover, Collect & Sell Extremely HOT NFTs
                      </h1>
                      <div className="description">
                        <b>
                          {" "}
                          From creators you love and earn a share from their
                          every revenue.
                        </b>{" "}
                        <span style={{ fontWeight: "700" }}>
                          OR mint your own NFTs and let your crush buy them to
                          support you.
                        </span>
                      </div>
                      <div className="row mt-5">
                        <div className="col-sm-6 my-2">
                          <a href="#nftTabContent" className="btn btn-custom">
                            EXPLORE NFTs
                          </a>
                        </div>
                        <div className="col-sm-6 my-2">
                          <a href="/create-nft" className="btn btn-custom">
                            MINT YOUR OWN NFTs
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="site-content layout-1">
              <div className="container">
                <div className="row">
                  <div className="col-md-2" />
                  <div className="col-md-8">
                    <input
                      placeholder="Search NFTs by name, symbol or creator's wallet"
                      className="form-control my-5"
                      style={{
                        backgroundColor: "#000",
                        color: "#fff",
                        width: "100%",
                        height: "60px",
                        border: "#aaa 1px solid",
                      }}
                      type="search"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    {!isLoading && (
                      <div>
                        {results.length > 0 &&
                          results.map((p, i) => (
                            <>
                              <div className="card nft-card">
                                <div className="card-body">
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ gap: "20px" }}
                                  >
                                    <img
                                      src={domain + p.image}
                                      className="card-img-topa"
                                      alt={p.name}
                                      width={150}
                                    />
                                    <div>
                                      <h5 className="card-title">
                                        {p.name} ({p.symbol})
                                      </h5>
                                      <p className="card-text">
                                        {p.owned_supply} / {p.supply} holders
                                      </p>
                                      <p className="price">
                                        {formatNumber(p.price)} credits{" "}
                                        <span className="text-primary">
                                          ($
                                          {formatNumber(
                                            Math.round(tokenPrice * p.price)
                                          )}
                                          )
                                        </span>
                                      </p>
                                      <div className="text-center mt-1">
                                        <button
                                          className="btn btn-custom"
                                          data-toggle="modal"
                                          data-target={`#searchModal-${i}`}
                                          disabled={
                                            !isConnected ||
                                            address === p.wallet ||
                                            p.owned_supply >= p.supply
                                          }
                                        >
                                          {p.owned_supply !== p.supply
                                            ? "Buy this NFT"
                                            : "Sold out"}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*BUY NFT modal*/}
                              <div
                                className="modal fade"
                                id={`searchModal-${i}`}
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby={`sModalLabel-${i}`}
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-md"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h3
                                        className="modal-title"
                                        id={`sModalLabel-${i}`}
                                      >
                                        Buy NFT
                                      </h3>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      You are buying<b> {p.name}</b> ({p.symbol}
                                      ) for {formatNumber(p.price)} credits ($
                                      {formatNumber(
                                        Math.round(tokenPrice * p.price)
                                      )}
                                      ) each and your credit balance is{" "}
                                      {formatNumber(user.credits)} ($
                                      {formatNumber(
                                        Math.round(tokenPrice * user.credits)
                                      )}
                                      ). Enter the quantity you want to buy and
                                      hit the PROCEED button.
                                      <div className="my-4">
                                        You can buy up to{" "}
                                        {+p.supply - p.owned_supply} {p.symbol}{" "}
                                        max.
                                        <input
                                          placeholder="Quantity"
                                          className="form-control my-5"
                                          style={{
                                            backgroundColor: "#000",
                                            color: "#fff",
                                            width: "100%",
                                            height: "60px",
                                            border: "#aaa 1px solid",
                                          }}
                                          type="number"
                                          value={quantity}
                                          onChange={(e) =>
                                            setQuantity(e.target.value)
                                          }
                                        />
                                        {quantity && (
                                          <>
                                            You will earn{" "}
                                            {getPercent(quantity, p.supply)}%
                                            share from{" "}
                                            {!p.username
                                              ? shortenAddress(p.wallet)
                                              : p.username}
                                            's subscription fees
                                          </>
                                        )}
                                      </div>
                                    </div>

                                    <div className="modal-footer">
                                      {quantity && (
                                        <>
                                          <button
                                            type="button"
                                            className="btn btn-custom"
                                            onClick={() =>
                                              buyNft(
                                                p.name,
                                                p.symbol,
                                                p.wallet,
                                                p.id,
                                                quantity,
                                                p.price,
                                                p.supply,
                                                p.owned_supply,
                                                p.image
                                              )
                                            }
                                          >
                                            Proceed to pay{" "}
                                            {formatNumber(+p.price * +quantity)}{" "}
                                            credits
                                          </button>
                                        </>
                                      )}
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    )}
                    {isLoading && <p>Loading...</p>}
                  </div>
                  <div className="col-md-2" />
                </div>
              </div>
              <section
                className="py-5 px-3 overlay-sectiona"
                style={{
                  backgroundImage: `url(${domain}assets/images/nft-bg.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "fixed",
                  backgroundSize: "cover",
                }}
              >
                <div className="content">
                  <ul className="nav nav-tabs" id="nftTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="new-tab"
                        data-toggle="tab"
                        href="#new"
                        role="tab"
                        aria-controls="all"
                        aria-selected="true"
                      >
                        New Arrivals
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="popular-tab"
                        data-toggle="tab"
                        href="#popular"
                        role="tab"
                        aria-controls="female"
                        aria-selected="false"
                      >
                        Popular NFTs
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="mine-tab"
                        data-toggle="tab"
                        href="#mine"
                        role="tab"
                        aria-controls="male"
                        aria-selected="false"
                      >
                        My NFTs
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="nftTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="new"
                      role="tabpanel"
                      aria-labelledby="new-arrivals-tab"
                    >
                      <div className="row mt-4">
                        {newNft.length > 0 ? (
                          newNft.map((p, i) => (
                            <div key={i} className="col-md-3">
                              <div className="card nft-card">
                                <img
                                  src={domain + p.image}
                                  className="card-img-top"
                                  alt="NFT"
                                />
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <img
                                        src={domain + p.profile_photo}
                                        className="avatar"
                                        alt="Avatar"
                                      />
                                      <span className="badge badge-secondary">
                                        By{" "}
                                        <a
                                          href={`profile?user=${
                                            !p.username ? p.wallet : p.username
                                          }`}
                                          target="_blank"
                                        >
                                          {!p.username
                                            ? shortenAddress(p.wallet)
                                            : p.username}
                                        </a>{" "}
                                        {p.verified === "yes" && (
                                          <i
                                            className="fa fa-check-circle text-success"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <h5 className="card-title">
                                    {p.name} ({p.symbol})
                                  </h5>
                                  <p className="card-text">
                                    {p.owned_supply} / {p.supply} holders
                                  </p>
                                  <p className="price">
                                    {formatNumber(p.price)} credits{" "}
                                    <span className="text-primary">
                                      ($
                                      {formatNumber(
                                        Math.round(tokenPrice * p.price)
                                      )}
                                      )
                                    </span>
                                  </p>
                                  <div
                                    className={`${
                                      searchArrayBool(p.liked_by_users, address)
                                        ? "likes"
                                        : "not-like"
                                    }`}
                                  >
                                    <button
                                      onClick={() => handleLike(p.id)}
                                      className={`${
                                        searchArrayBool(
                                          p.liked_by_users,
                                          address
                                        )
                                          ? "likes"
                                          : "not-like"
                                      }`}
                                    >
                                      <i className="fa fa-heart" />{" "}
                                      {searchArrayBool(
                                        p.liked_by_users,
                                        address
                                      )
                                        ? "Unlike"
                                        : "Like"}
                                    </button>{" "}
                                    <button
                                      className="text-primary ml-2"
                                      data-toggle="modal"
                                      data-target={`#likeModal-${i}`}
                                    >
                                      {Math.round(formatNumber(p.like_count))}
                                    </button>
                                  </div>

                                  <div className="text-center mt-1">
                                    <button
                                      className="btn btn-custom"
                                      data-toggle="modal"
                                      data-target={`#buyModal-${i}`}
                                      disabled={
                                        !isConnected || address === p.wallet
                                      }
                                    >
                                      Buy this NFT
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/*BUY NFT modal*/}
                              <div
                                className="modal fade"
                                id={`buyModal-${i}`}
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby={`buyModalLabel-${i}`}
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-md"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h3
                                        className="modal-title"
                                        id={`likeModalLabel-${i}`}
                                      >
                                        Buy NFT
                                      </h3>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      You are buying<b> {p.name}</b> ({p.symbol}
                                      ) for {formatNumber(p.price)} credits ($
                                      {formatNumber(
                                        Math.round(tokenPrice * p.price)
                                      )}
                                      ) each and your credit balance is{" "}
                                      {formatNumber(user.credits)} ($
                                      {formatNumber(
                                        Math.round(tokenPrice * user.credits)
                                      )}
                                      ). Enter the quantity you want to buy and
                                      hit the PROCEED button.
                                      <div className="my-4">
                                        You can buy up to{" "}
                                        {+p.supply - p.owned_supply} {p.symbol}{" "}
                                        max.
                                        <input
                                          placeholder="Quantity"
                                          className="form-control my-5"
                                          style={{
                                            backgroundColor: "#000",
                                            color: "#fff",
                                            width: "100%",
                                            height: "60px",
                                            border: "#aaa 1px solid",
                                          }}
                                          type="number"
                                          value={quantity}
                                          onChange={(e) =>
                                            setQuantity(e.target.value)
                                          }
                                        />
                                        {quantity && (
                                          <>
                                            You will earn{" "}
                                            {getPercent(quantity, p.supply)}%
                                            share from{" "}
                                            {!p.username
                                              ? shortenAddress(p.wallet)
                                              : p.username}
                                            's subscription fees
                                          </>
                                        )}
                                      </div>
                                    </div>

                                    <div className="modal-footer">
                                      {quantity && (
                                        <>
                                          <button
                                            type="button"
                                            className="btn btn-custom"
                                            onClick={() =>
                                              buyNft(
                                                p.name,
                                                p.symbol,
                                                p.wallet,
                                                p.id,
                                                quantity,
                                                p.price,
                                                p.supply,
                                                p.owned_supply,
                                                p.image
                                              )
                                            }
                                          >
                                            Proceed to pay{" "}
                                            {formatNumber(+p.price * +quantity)}{" "}
                                            credits
                                          </button>
                                        </>
                                      )}
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*LIKES modal*/}
                              <div
                                className="modal fade"
                                id={`likeModal-${i}`}
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby={`likeModalLabel-${i}`}
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-sm"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h3
                                        className="modal-title"
                                        id={`likeModalLabel-${i}`}
                                      >
                                        NFT Likes
                                      </h3>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      {p.liked_by_users.length > 0 &&
                                        p.liked_by_users.map((u, index) => (
                                          <div
                                            key={index}
                                            className="d-flex align-items-center p-2"
                                          >
                                            <img
                                              src={domain + u.profile_photo}
                                              style={{
                                                height: "30px",
                                                width: "30px",
                                                borderRadius: "50%",
                                              }}
                                            />
                                            <span className="ml-1">
                                              {" "}
                                              <a
                                                href={`profile?user=${
                                                  !u.username
                                                    ? u.wallet
                                                    : u.username
                                                }`}
                                                style={{ color: "#fff" }}
                                                target="_blank"
                                              >
                                                {!u.username
                                                  ? shortenAddress(u.wallet)
                                                  : u.username}
                                              </a>{" "}
                                            </span>
                                          </div>
                                        ))}
                                    </div>

                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            <img src="assets/images/fly.gif" height={100} />
                            <p>No NFTs here yet...</p>
                          </div>
                        )}
                      </div>
                      {/* Repeat for other cards */}
                      {newNft.length > 0 && (
                        <Pagination
                          currentPage={pagination.current_page}
                          totalPages={pagination.total_pages}
                          onPageChange={(page) => getNewNft(page)}
                        />
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="popular"
                      role="tabpanel"
                      aria-labelledby="female-tab"
                    >
                      <div className="row mt-4">
                        {popularNft.length > 0 ? (
                          popularNft.map((p, i) => (
                            <div key={i} className="col-md-3">
                              <div className="card nft-card">
                                <img
                                  src={domain + p.image}
                                  className="card-img-top"
                                  alt="NFT"
                                />
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <img
                                        src={domain + p.profile_photo}
                                        className="avatar"
                                        alt="Avatar"
                                      />
                                      <span className="badge badge-secondary">
                                        By{" "}
                                        <a
                                          href={`profile?user=${
                                            !p.username ? p.wallet : p.username
                                          }`}
                                          target="_blank"
                                        >
                                          {!p.username
                                            ? shortenAddress(p.wallet)
                                            : p.username}
                                        </a>{" "}
                                        {p.verified === "yes" && (
                                          <i
                                            className="fa fa-check-circle text-success"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <h5 className="card-title">
                                    {p.name} ({p.symbol})
                                  </h5>
                                  <p className="card-text">
                                    {p.owned_supply} / {p.supply} holders
                                  </p>
                                  <p className="price">
                                    {formatNumber(p.price)} credits{" "}
                                    <span className="text-primary">
                                      ($
                                      {formatNumber(
                                        Math.round(tokenPrice * p.price)
                                      )}
                                      )
                                    </span>
                                  </p>
                                  <div
                                    className={`${
                                      searchArrayBool(p.liked_by_users, address)
                                        ? "likes"
                                        : "not-like"
                                    }`}
                                  >
                                    <button
                                      onClick={() => handleLike(p.id)}
                                      className={`${
                                        searchArrayBool(
                                          p.liked_by_users,
                                          address
                                        )
                                          ? "likes"
                                          : "not-like"
                                      }`}
                                    >
                                      <i className="fa fa-heart" />{" "}
                                      {searchArrayBool(
                                        p.liked_by_users,
                                        address
                                      )
                                        ? "Unlike"
                                        : "Like"}
                                    </button>{" "}
                                    <button
                                      className="text-primary ml-2"
                                      data-toggle="modal"
                                      data-target={`#poplikeModal-${i}`}
                                    >
                                      {Math.round(formatNumber(p.like_count))}
                                    </button>
                                  </div>

                                  <div className="text-center mt-1">
                                    <button
                                      className="btn btn-custom"
                                      data-toggle="modal"
                                      data-target={`#popbuyModal-${i}`}
                                      disabled={
                                        !isConnected || address === p.wallet
                                      }
                                    >
                                      Buy this NFT
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/*BUY NFT modal*/}
                              <div
                                className="modal fade"
                                id={`popbuyModal-${i}`}
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby={`popbuyModalLabel-${i}`}
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-md"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h3
                                        className="modal-title"
                                        id={`poplikeModalLabel-${i}`}
                                      >
                                        Buy NFT
                                      </h3>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      You are buying<b> {p.name}</b> ({p.symbol}
                                      ) for {formatNumber(p.price)} credits ($
                                      {formatNumber(
                                        Math.round(tokenPrice * p.price)
                                      )}
                                      ) each and your credit balance is{" "}
                                      {formatNumber(user.credits)} ($
                                      {formatNumber(
                                        Math.round(tokenPrice * user.credits)
                                      )}
                                      ). Enter the quantity you want to buy and
                                      hit the PROCEED button.
                                      <div className="my-4">
                                        You can buy up to{" "}
                                        {+p.supply - p.owned_supply} {p.symbol}{" "}
                                        max.
                                        <input
                                          placeholder="Quantity"
                                          className="form-control my-5"
                                          style={{
                                            backgroundColor: "#000",
                                            color: "#fff",
                                            width: "100%",
                                            height: "60px",
                                            border: "#aaa 1px solid",
                                          }}
                                          type="number"
                                          value={quantity}
                                          onChange={(e) =>
                                            setQuantity(e.target.value)
                                          }
                                        />
                                        {quantity && (
                                          <>
                                            You will earn{" "}
                                            {getPercent(quantity, p.supply)}%
                                            share from{" "}
                                            {!p.username
                                              ? shortenAddress(p.wallet)
                                              : p.username}
                                            's subscription fees
                                          </>
                                        )}
                                      </div>
                                    </div>

                                    <div className="modal-footer">
                                      {quantity && (
                                        <>
                                          <button
                                            type="button"
                                            className="btn btn-custom"
                                            onClick={() =>
                                              buyNft(
                                                p.name,
                                                p.symbol,
                                                p.wallet,
                                                p.id,
                                                quantity,
                                                p.price,
                                                p.supply,
                                                p.owned_supply,
                                                p.image
                                              )
                                            }
                                          >
                                            Proceed to pay{" "}
                                            {formatNumber(+p.price * +quantity)}{" "}
                                            credits
                                          </button>
                                        </>
                                      )}
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*LIKES modal*/}
                              <div
                                className="modal fade"
                                id={`poplikeModal-${i}`}
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby={`poplikeModalLabel-${i}`}
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-sm"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h3
                                        className="modal-title"
                                        id={`likeModalLabel-${i}`}
                                      >
                                        NFT Likes
                                      </h3>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      {p.liked_by_users.length > 0 &&
                                        p.liked_by_users.map((u, index) => (
                                          <div
                                            key={index}
                                            className="d-flex align-items-center p-2"
                                          >
                                            <img
                                              src={domain + u.profile_photo}
                                              style={{
                                                height: "30px",
                                                width: "30px",
                                                borderRadius: "50%",
                                              }}
                                            />
                                            <span className="ml-1">
                                              {" "}
                                              <a
                                                href={`profile?user=${
                                                  !u.username
                                                    ? u.wallet
                                                    : u.username
                                                }`}
                                                style={{ color: "#fff" }}
                                                target="_blank"
                                              >
                                                {!u.username
                                                  ? shortenAddress(u.wallet)
                                                  : u.username}
                                              </a>{" "}
                                            </span>
                                          </div>
                                        ))}
                                    </div>

                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            <img src="assets/images/fly.gif" height={100} />
                            <p>No NFTs here yet...</p>
                          </div>
                        )}

                        {/* Repeat for other cards */}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="mine"
                      role="tabpanel"
                      aria-labelledby="male-tab"
                    >
                      <div className="row mt-4">
                        {myNft.length > 0 ? (
                          myNft.map((p, i) => (
                            <div key={i} className="col-md-4">
                              <div className="card nft-card">
                                <img
                                  src={domain + p.image}
                                  className="card-img-top"
                                  alt="NFT"
                                />
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <img
                                        src={domain + p.profile_photo}
                                        className="avatar"
                                        alt="Avatar"
                                      />
                                      <span className="badge badge-secondary">
                                        By{" "}
                                        <a
                                          href={`profile?user=${
                                            !p.username ? p.wallet : p.username
                                          }`}
                                          target="_blank"
                                        >
                                          {!p.username
                                            ? shortenAddress(p.wallet)
                                            : p.username}
                                        </a>{" "}
                                        {p.verified === "yes" && (
                                          <i
                                            className="fa fa-check-circle text-success"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <h5 className="card-title">
                                    {p.name} ({p.symbol})
                                  </h5>
                                  <p className="card-text">
                                    {p.amount} {p.symbol} ({p.percent}% share of{" "}
                                    {!p.username
                                      ? shortenAddress(p.wallet)
                                      : p.username}
                                    's subscription earnings) owned by you since{" "}
                                    {formatLocalTime(p.add_date)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            <img src="assets/images/fly.gif" height={100} />
                            <p>No NFTs here yet...</p>
                          </div>
                        )}

                        {/* Repeat for other cards */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default ProfileNFT;
