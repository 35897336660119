const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(
      <button
        key={i}
        onClick={() => onPageChange(i)}
        className={`btn ${
          i === currentPage ? "btn-danger" : "btn-outline-danger"
        } mx-1`}
      >
        {i}
      </button>
    );
  }

  return <div className="d-flex justify-content-center my-4">{pages}</div>;
};

export default Pagination;
