import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import { removeDecimals } from "../../utils/helpers";

function WatchCategories() {
 const [categories, setCategories] = useState([]);

 const { apiURL, domain } = useContext(Context);

 useEffect(() => {
  const fetchCategories = async () => {
   try {
    const response = await fetch(apiURL, {
     method: "POST",
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
     body: new URLSearchParams({
      action: "get_all_categories_with_images",
     }),
    });

    if (!response.ok) {
     throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    if (data.error) {
     console.error("Error:", data.error);
    } else {
     console.log("CAT", data);
     setCategories(data);
    }
   } catch (error) {
    console.error("Error:", error.message);
   }
  };

  fetchCategories();
 }, []);

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div
     id="main-content"
     style={{
      backgroundColor: "#1e1e1e",
     }}
    >
     <div className="site-content layout-1">
      <div className="container">
       <div className="row text-center my-5">
        <div className="col-12">
         <h2 className="display-4">ALL CATEGORIES</h2>
        </div>
       </div>

       <div className="row">
        {categories.length > 0 &&
         categories.map((c, i) => (
          <div key={i} className="col-sm-6 col-md-4 col-lg-2">
           <a href={`categories?cat=${c.category}`} className="categoryBox tm_categoryBox">
            <div className="link-container">
             <div className="categoryTitle">
              <p className="tm_category_title">
               {c.category}
               <br />
               <span>{removeDecimals(c.post_count)} video(s)</span>
              </p>
             </div>
             <img src={`${domain}assets/images/categories/${c.image}`} alt={c.category} />
             <div className="categoryBG"></div>
            </div>
           </a>
          </div>
         ))}
       </div>
      </div>
     </div>
    </div>
    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default WatchCategories;
