import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import { removeDecimals, getQueryParam } from "../../utils/helpers";
import Pagination from "../../components/Pagination";

function Category() {
 const [pagination, setPagination] = useState({
  current_page: 1,
  total_pages: 1,
  total_records: 0,
 });
 const [video, setVideo] = useState([]);

 const { apiURL, domain, setLoading } = useContext(Context);

 const getVideo = async (page = 1, limit = 20) => {
  let cat;
  setLoading(true);

  try {
   cat = getQueryParam("cat");
   if (cat == null) return;

   const response = await fetch(apiURL, {
    method: "POST",
    headers: {
     "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
     action: "get_a_category_videos",
     category: cat,
     page: page,
     limit: limit,
    }),
   });

   if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
   }

   const data = await response.json();
   if (data.error) {
    console.error("Error:", data.error);
   } else {
    setVideo(data.records);
    setPagination(data.pagination);
    console.log("Fetched Video URL:", data);
   }
  } catch (error) {
   console.error("Error:", error.message);
  }
  setLoading(false);
 };

 useEffect(() => {
  getVideo(1);
 }, []);

 return (
  <div>
   <div id="wrapper-container">
    <Header />
    <div
     id="main-content"
     style={{
      backgroundColor: "#1e1e1e",
     }}
    >
     <div className="site-content layout-1">
      <div className="container">
       <div className="row text-center my-5">
        <div className="col-12">
         <h2 className="display-4">{getQueryParam("cat")} Category</h2>
        </div>
       </div>

       <div className="row">
        {video.length > 0 &&
         video.map((v, i) => (
          <div key={i} className="col-sm-12 col-md-4 col-lg-4">
           <div className="item-slick" style={{ cursor: "pointer" }}>
            <div className="post-item">
             <div className="pic" style={{ position: "relative" }}>
              <img src={domain + v.cover_photo} alt="IMG" className="vr-slider" />

              <div
               className="overlay"
               style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.4)", // Semi-transparent overlay
                zIndex: 2,
               }}
               onClick={() => window.open(`single-video?vid=${v.id}`, "_blank")}
              />
              <div
               className="meta-info"
               style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                color: "#fff",
                zIndex: 2,
               }}
              >
               <div className="imdb">
                <span className="value">{v.views}</span> VIEWS
               </div>
              </div>
              <div
               style={{
                position: "absolute",
                top: "50%",
                left: "0%",
                transform: "translate(-0%, -50%)",
                color: "#fff",
                textAlign: "center",
                padding: "0 10px",
                zIndex: 2,
               }}
               onClick={() => window.open(`single-video?vid=${v.id}`, "_blank")}
              >
               <h4 className="title">{v.title}</h4>
              </div>
              <div
               className="label"
               style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                background: "#e40914",
                padding: "5px 10px",
                color: "#fff",
                borderRadius: "5px",
                zIndex: 2,
               }}
              >
               {v.category}
              </div>
             </div>
            </div>
           </div>
          </div>
         ))}
       </div>
       {video.length > 0 && (
        <Pagination
         currentPage={pagination.current_page}
         totalPages={pagination.total_pages}
         onPageChange={(page) => getVideo(page)}
        />
       )}
       {video.length === 0 && (
        <div className="text-center">
         <img src="assets/images/fly.gif" height={100} />
         <p>No videos here yet...</p>
        </div>
       )}
      </div>
     </div>
    </div>
    <Footer />
   </div>
   <div id="back-to-top" className="btn-back-to-top">
    <i className="ion ion-ios-arrow-thin-up" />
   </div>
  </div>
 );
}

export default Category;
