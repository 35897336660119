import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import axios from "axios";
import $ from "jquery";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
  getPercentage,
  showAlert,
} from "../../utils/helpers";
import "../styles/post-video.css";
const now = Math.floor(Date.now() / 1000);

function BecomeCreator() {
  const [video, setVideo] = useState({});

  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
    user,
    getUser,
  } = useContext(Context);

  const [creatorType, setCreatorType] = useState("real");

  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [uploadVideo, setUploadVideo] = useState(null);
  const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
  const [uploadVideoPreview, setUploadVideoPreview] = useState(null);
  const [videoDurationValid, setVideoDurationValid] = useState(true);
  const [bio, setBio] = useState("");
  const [contentPrice, setContentPrice] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPrev, setProfilePhotoPrev] = useState(null);

  const [username, setUsername] = useState("");
  const [country, setCountry] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");

  const [idType, setIdType] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [frontPrev, setFrontPrev] = useState(null);
  const [backPreview, setBackPreview] = useState(null);
  const [selfPreview, setSelfPreview] = useState(null);
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [self, setSelf] = useState(null);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressKYC, setUploadProgressKYC] = useState(0);
  const dobRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/;

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setBio(user.bio);
      setContentPrice(user.content_price);
      setEmail(user.email);
      setGender(user.gender);
      setUsername(user.username);
      setSurname(user.surname);
      setFirstName(user.first_name);
      setDob(user.dob);
      setCountry(user.country);
      setContentPrice(user.content_price);
    }
  }, [user, address]);

  const handleCoverPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverPhoto(file);
      setCoverPhotoPreview(URL.createObjectURL(file));
    }
  };

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePhoto(file);
      setProfilePhotoPrev(URL.createObjectURL(file));
    }
  };

  const handleFrontPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFront(file);
      setFrontPrev(URL.createObjectURL(file));
    }
  };

  const handleBackPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBack(file);
      setBackPreview(URL.createObjectURL(file));
    }
  };

  const handleSelfPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelf(file);
      setSelfPreview(URL.createObjectURL(file));
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // Check if wallet is connected
    if (!isConnected) {
      return showAlert({
        title: "Error!",
        text: "Please connect your wallet and try again.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    // Validate date of birth format
    if (!dobRegex.test(dob)) {
      return showAlert({
        title: "Error!",
        text: "Please enter the date of birth in the format DD-MM-YYYY.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    // Construct form data
    const formData = new FormData();
    formData.append("wallet", address);
    formData.append("bio", bio);
    formData.append("content_price", contentPrice);
    formData.append("country", country);
    formData.append("first_name", firstName);
    formData.append("surname", surname);
    formData.append("dob", dob);
    formData.append("email", email);
    formData.append("current_email", user.email);
    formData.append("gender", gender);

    if (user.username == null) {
      formData.append("username", username);
    }
    if (coverPhoto != null) {
      formData.append("coverPhoto", coverPhoto);
    }
    if (profilePhoto != null) {
      formData.append("profilePhoto", profilePhoto);
    }
    formData.append("action", "become-creator");
    formData.append("is_creator", "yes");
    formData.append("creator_type", creatorType);

    try {
      // Debug FormData contents
      console.log("FormData:", [...formData]);

      // Make the API call
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      // Handle response
      if (response.data.success) {
        getUser(address);
        showAlert({
          title: "Successful!",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "Sounds good",
        });
      } else {
        console.log("Error Response:", response);
        showAlert({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Request Error:", error);
      showAlert({
        title: "Error!",
        text: "An error occurred while updating the details. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{
                  backgroundImage: `url(${user.cover_photo})`,
                }}
              >
                <div className="overlay-top-header" />
                <div
                  className="content container"
                  style={{ paddingBottom: "30px" }}
                >
                  <h1>BECOME A CREATOR</h1>
                  <div className="description">
                    Fill out your profile details to become a creator.
                  </div>
                </div>
              </div>
            </div>
            <div className="site-content layout-1">
              <div className="container">
                <div className="form-container">
                  {!isConnected ? (
                    <div className="alert-warning p-3 my-3 rounded">
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{" "}
                      Please connect your wallet to be able to become a creator.
                    </div>
                  ) : (
                    <>
                      <form onSubmit={handleUpdate}>
                        {user.is_creator !== "yes" ? (
                          <div className="row">
                            <div className="col-md-12 my-3">
                              <h1>What type of creator are you?</h1>
                              <div className="row">
                                <div className="col-md-4">
                                  <label htmlFor="real" className="rcontainer">
                                    Real creator
                                    <input
                                      id="real"
                                      value="real"
                                      type="radio"
                                      name="creator"
                                      onChange={(e) =>
                                        setCreatorType(e.target.value)
                                      }
                                      checked={creatorType === "real"}
                                      required
                                    />
                                    <span className="rcheckmark"></span>
                                  </label>
                                </div>

                                <div className="col-md-4">
                                  <label htmlFor="ai" className="rcontainer">
                                    Ai creator
                                    <input
                                      id="ai"
                                      type="radio"
                                      name="creator"
                                      value="ai"
                                      onChange={(e) =>
                                        setCreatorType(e.target.value)
                                      }
                                      checked={creatorType === "ai"}
                                    />
                                    <span className="rcheckmark"></span>
                                  </label>
                                </div>

                                <div className="col-md-4">
                                  <label htmlFor="both" className="rcontainer">
                                    Both
                                    <input
                                      id="both"
                                      type="radio"
                                      name="creator"
                                      value="both"
                                      onChange={(e) =>
                                        setCreatorType(e.target.value)
                                      }
                                      checked={creatorType === "both"}
                                    />
                                    <span className="rcheckmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Surname (hidden from public)</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="John"
                                  onChange={(e) => setSurname(e.target.value)}
                                  value={surname}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>First Name (hidden from public)</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Jane"
                                  onChange={(e) => setFirstName(e.target.value)}
                                  value={firstName}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="username">
                                  Display name (public)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  placeholder="Pick a username"
                                  onChange={(e) => setUsername(e.target.value)}
                                  value={username}
                                  required
                                  readOnly={user.username != null}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Date of Birth (DD-MM-YYYY)</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="DD-MM-YYYY"
                                  onChange={(e) => setDob(e.target.value)}
                                  value={dob}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Country/Nationality</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Type country name"
                                  onChange={(e) => setCountry(e.target.value)}
                                  value={country}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Enter your email"
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-8">
                              <div className="form-group">
                                <label>
                                  How many $Ai18 tokens to charge your fans per
                                  month
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter amount"
                                  onChange={(e) =>
                                    setContentPrice(e.target.value)
                                  }
                                  value={contentPrice}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <label>Gender</label>
                              <div className="row">
                                <div className="col-md-6">
                                  <label htmlFor="Gmale" className="rcontainer">
                                    Male
                                    <input
                                      id="Gmale"
                                      type="radio"
                                      name="gender"
                                      value="male"
                                      onChange={(e) =>
                                        setGender(e.target.value)
                                      }
                                      checked={gender === "male"}
                                      required
                                    />
                                    <span className="rcheckmark"></span>
                                  </label>
                                </div>
                                <div className="col-md-6">
                                  <label
                                    htmlFor="Gfemale"
                                    className="rcontainer"
                                  >
                                    Female
                                    <input
                                      id="Gfemale"
                                      type="radio"
                                      name="gender"
                                      value="female"
                                      onChange={(e) =>
                                        setGender(e.target.value)
                                      }
                                      checked={gender === "female"}
                                    />
                                    <span className="rcheckmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="videoDescription">
                                  About Me
                                </label>
                                <textarea
                                  className="form-control"
                                  id="videoDescription"
                                  style={{ height: "120px" }}
                                  placeholder="Tell fans something about you."
                                  value={bio}
                                  required
                                  onChange={(e) => setBio(e.target.value)}
                                  maxLength={500}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 text-center">
                              <div className="form-group">
                                <label htmlFor="profilePhoto">
                                  <img
                                    src={profilePhotoPrev || user.profile_photo}
                                    height={150}
                                    alt="profile placeholder"
                                  />
                                </label>
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="profilePhoto"
                                    accept="image/*"
                                    onChange={handleProfilePhotoChange}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="profilePhoto"
                                  >
                                    Choose profile photo
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 text-center">
                              <div className="form-group">
                                <label htmlFor="coverPhoto">
                                  <img
                                    src={coverPhotoPreview || user.cover_photo}
                                    height={150}
                                    alt="cover placeholder"
                                  />
                                </label>
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="coverPhoto"
                                    accept="image/*"
                                    onChange={handleCoverPhotoChange}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="coverPhoto"
                                  >
                                    Choose cover photo
                                  </label>
                                </div>
                              </div>
                            </div>

                            {uploadProgress > 0 && (
                              <div className="col-md-12 my-4">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${uploadProgress}%` }}
                                    aria-valuenow={uploadProgress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    {uploadProgress}%
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="col-md-12 text-center my-2">
                              <button type="submit" className="btn btn-custom">
                                Submit
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="alert-danger p-3 my-3 rounded">
                            <i
                              className="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>{" "}
                            Sorry, you are already a creator.
                          </div>
                        )}
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default BecomeCreator;
