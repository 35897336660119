import React, { useState, useContext, useEffect } from "react";
import { Context } from "../context/contex";
import { formatNumber, shortenAddress, copy } from "../utils/helpers";
import TabBar from "./Tabbar";
const Footer = () => {
  const [topCreators, setTopCreators] = useState([]);
  const [topViewers, setTopViewers] = useState([]);

  const { settings, tokenPrice, apiURL, domain } = useContext(Context);
  const getTopCreators = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_all_top_creators" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setTopCreators(data);
        console.log("CREATORS", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getTopCreators();
  }, []);

  const getTopViewers = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_top_viewers" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setTopViewers(data);
        console.log("VIEWERS", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getTopViewers();
  }, []);

  return (
    <>
      <footer className="site-footer layout-1">
        <div className="footer-sidebars">
          <div className="container">
            <div className="thim-3-col">
              <aside className="widget widget_thim_layout_builder">
                <div className="wpb_single_image">
                  <img src="assets/images/logo.png" alt="logo" width={170} />
                </div>
                <div className="wpb_text_column">
                  <p>
                    We are creating the first adult digital community where
                    users – viewers and content creators – EARN REWARDS for the
                    interactions they generate, in an environment they already
                    tend to visit almost daily.
                  </p>
                </div>
                <form className="yikes-easy-mc-form layout-footer">
                  <label>
                    18plus Contract Address
                    <input
                      type="text"
                      placeholder="Contract"
                      value={settings.token || ""}
                      readOnly
                    />
                  </label>
                  <button onClick={() => copy(settings.token)} type="button">
                    Copy
                  </button>
                  <p />
                </form>
                <div className="bp-element bp-element-social-links vblog-layout-footer">
                  <div className="wrap-element">
                    <a
                      href="https://t.me/ai18pluspresale"
                      className="social-item"
                    >
                      <i className="ions fa fa-telegram" />
                    </a>
                    <a href="#" className="social-item">
                      <i className="ion ion-social-twitter" />
                    </a>
                  </div>
                </div>
              </aside>
              <aside className="widget widget_thim_layout_builder">
                <div className="bp-element bp-element-posts vblog-layout-list-footer">
                  <div className="wrap-element">
                    <div className="heading-post">
                      <h3 className="title">TOP 20 CREATORS</h3>
                    </div>
                    <div
                      className="list-posts"
                      style={{ height: "300px", overflow: "auto" }}
                    >
                      {topCreators.length > 0 &&
                        topCreators.map((c, i) => (
                          <div className="item" key={i}>
                            <div className="pic">
                              <a
                                href={`profile?user=${
                                  c.username ? c.username : c.wallet
                                }`}
                              >
                                <img
                                  src={`${domain}${c.profile_photo}`}
                                  alt="IMG"
                                  style={{
                                    width: "auto",
                                    height: "50px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </a>
                            </div>
                            <div className="text">
                              <h4 className="title">
                                <a
                                  href={`profile?user=${
                                    c.username ? c.username : c.wallet
                                  }`}
                                >
                                  {c.username
                                    ? c.username
                                    : shortenAddress(c.wallet)}
                                </a>
                                {c.verified === "yes" && (
                                  <i
                                    className="fa fa-check-circle text-success"
                                    aria-hidden="true"
                                  />
                                )}
                              </h4>
                              <div className="date">
                                ${formatNumber(tokenPrice * c.total_earned)}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </aside>
              <aside className="widget widget_thim_layout_builder">
                <div className="bp-element bp-element-categories layout-list-1">
                  <div className="wrap-element">
                    <h3 className="title">TOP 20 VIEWERS</h3>
                    <div style={{ height: "300px", overflow: "auto" }}>
                      <ul className="list-categories">
                        {topViewers.length > 0 &&
                          topViewers.map((c, i) => (
                            <li key={i} className="cat-item">
                              <a
                                href={`profile?user=${
                                  c.username ? c.username : c.wallet
                                }`}
                              >
                                {c.username
                                  ? c.username
                                  : shortenAddress(c.wallet)}{" "}
                                {c.verified === "yes" && (
                                  <i
                                    className="fa fa-check-circle text-success"
                                    aria-hidden="true"
                                  />
                                )}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="thim-1-col">
              <div className="copyright-text">Copyright 2024. Ai18Plus</div>
            </div>
          </div>
        </div>
        <TabBar />
      </footer>
    </>
  );
};

export default Footer;
